import React, { useState } from "react";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import api from "http";

export const DebitKreditForm = ({ data, setFormData }) => {
  // получаем историю дебита и кредита
  const GetData = (value) => {
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    api
      .post("get-debit-kredit-dukon", value)
      .then(function (res) {
        // console.log(res);
        setFormData(res.data);
      })
      .catch(function (error) {
        console.log("1", error);
      });
  };

  const [placement] = useState("bottomRight");
  return (
    <div>
      {" "}
      <Form name="" onFinish={GetData} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Boshlang'ich vaqti"
              name="sana1"
              rules={[
                {
                  required: true,
                  message: "Sanani tanlang",
                },
              ]}
            >
              <DatePicker
                placement={placement}
                placeholder="Boshlang'ich vaqti"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Tugash vaqti"
              name="sana2"
              rules={[
                {
                  required: true,
                  message: "Do'konni tanlang",
                },
              ]}
            >
              <DatePicker
                placement={placement}
                style={{ width: "100%" }}
                placeholder="Tugash vaqti"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            {" "}
            <Form.Item
              name="dukon_id"
              label="Do'konni tanlang"
              rules={[
                {
                  required: true,
                  message: "Sanani tanlang",
                },
              ]}
            >
              <Select
                showSearch
                style={{
                  width: "100%",
                }}
                // placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={data.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Saralashni bosing">
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Saralash
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
