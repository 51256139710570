import React, { useState, useEffect } from "react";
import { DebitKreditForm } from "./DebitKreditForm";
import { DebitKreditTable } from "./DebitKreditTable";
import LodingText from "../../Loading/LodingText";
import { Typography } from "antd";
import api from "http";

const { Title } = Typography;

export const DebitKredit = () => {
  // state для получения данных из формы
  const [formData, setFormData] = useState([]);
  // state для получения данных магазина
  const [data, setData] = useState([]);
  // получаем данные магазинов
  const GetTotalShop = () => {
    api
      .post("shops-get")
      .then(function (res) {
        setData(res.data);
        //   setIsLoading(true);
        //   console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetTotalShop();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Title level={4}>Do'kon bo'yicha hisobot</Title>
      <DebitKreditForm data={data} setFormData={setFormData} />
      {formData.length === 0 ? (
        <LodingText />
      ) : (
        <DebitKreditTable state={formData} />
      )}
    </div>
  );
};
