import React, { useRef, useState } from "react";
import { Table, Button, Row, Col, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Loding from "../Loading/Loding";
import { OrdersReportHisSingle } from "./OrdersReportHisSingle";
import { numberFormat } from "util/NumberFormat";

export const OrdersReportList = ({ filtered }) => {
  const componentRef = useRef();
  const [top] = useState("topLeft");

  const navigate = useNavigate();
  const OrdersList = (key) => {
    navigate(`${key}`);
  };

  // state для модалки ShopEdit
  const [isModalGotov, setIsModalGotov] = useState(false);
  // state для передачи record в ShopEdit
  const [modalGotov, setModalGotov] = useState(null);
  const closeGotovModal = () => {
    setIsModalGotov(false);
  };
  const ReportSingle = (record) => {
    setIsModalGotov(true);
    setModalGotov(record);
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      width: 20,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Do'kon nomi",
      dataIndex: "name",
    },
    {
      title: "Hisobi",
      dataIndex: "balans",
      render: (_, record) => {
        return numberFormat(record.balans);
      },
    },
    {
      title: "Amallar",
      dataIndex: "operation",
      width: 300,
      render: (_, record) => {
        return (
          <Row>
            <Button type="primary" onClick={() => OrdersList(record.id)}>
              {" "}
              Qarz buyurtmalar
            </Button>
            <Button
              type="primary"
              danger
              style={{ marginLeft: 10 }}
              onClick={() => ReportSingle(record)}
            >
              {" "}
              Hisobot
            </Button>
          </Row>
        );
      },
    },
  ];

  let TotalSum = 0;
  filtered.forEach(({ balans }) => {
    TotalSum += Number(balans);
  });

  if (filtered.length === 0) {
    return <Loding />;
  }
  return (
    <div>
      <Row justify="space-between" style={{ paddingTop: 15 }}>
        <Col>
          <p style={{ fontSize: 20 }}>
            Jami qarz{" "}
            <span style={{ fontSize: 22, color: "#ff4d4f" }}>
              {"\xa0\xa0\xa0" + numberFormat(TotalSum) + "\xa0"} so'm
            </span>
          </p>
          <p style={{ fontSize: 20 }}>
            Qarzdor do'konlar soni{" "}
            <span style={{ fontSize: 22, color: "#008080" }}>
              {"\xa0\xa0\xa0" + numberFormat(filtered.length) + "\xa0"}ta.
            </span>
          </p>
        </Col>
        <Col>
          {" "}
          <ReactToPrint
            trigger={() => <Button type="primary">печатать</Button>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
      <Table
        rowKey={(row) => row.id}
        bordered
        dataSource={filtered}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          position: [top],
        }}
        scroll={{
          x: 1124,
        }}
        ref={componentRef}
      />
      <Modal
        title={modalGotov?.name}
        open={isModalGotov}
        onCancel={closeGotovModal}
        footer={null}
        width={"90vw"}
      >
        <OrdersReportHisSingle modalGotov={modalGotov} />
      </Modal>
    </div>
  );
};
