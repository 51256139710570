import React from "react";

const LodingText = () => {
  const example = {
    margin: "20px 0",
    marginBottom: "20px",
    padding: "30px 50px",
    textAlign: "center",
    background: "rgba(0, 0, 0, 0.05)",
    borderRadius: "4px",
  };

  return <div style={example}>Hozircha yangi ma'lumot  mavjut emas......</div>;
};

export default LodingText;
