import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import UserEdit from "./UserEdit";
import { numberFormat } from "util/NumberFormat";
import css from "./Users.module.css";

export const WorkerList = ({ user, onDeleteUser }) => {
  const infoState = user.map((i, index) =>
    i.rol !== "programist" && i.id !== 1 ? (
      <tr
        //   style={{ background: index % 2 !== 0 ? "#eeee" : null }}
        className={css.WorkerTableTd}
        key={i.id}
      >
        <td>
          <span>{index + 1}</span>
        </td>
        <td>
          <span>{i.fio}</span>
        </td>
        <td className={css.WorkerTableTdata}>
          <span>{i.pasport}</span>
        </td>
        <td className={css.WorkerTableTdata}>
          <span>{i.rol}</span>
        </td>
        {/* <td className={css.WorkerTableTdata}>
			<span style={{ color: "#000" }}>{numberFormat(i.balans)}</span>
		 </td> */}
        <td className={css.WorkerTableTdata}>
          <span>{numberFormat(i.login)}</span>
        </td>

        <td className={css.WorkerTableTButton}>
          <UserEdit
            id={i.id}
            name={i.fio}
            pasport={i.pasport}
            balans={i.balans}
            login={i.login}
            parol={i.parol}
          />
          {""}

          <button
            type="button"
            className={css.Workerbutton}
            onClick={() => onDeleteUser(i.id)}
            title="Удалить"
          >
            <DeleteOutlined />
          </button>
        </td>
      </tr>
    ) : null
  );

  //   "id": "7",
  //   "login": "admina",
  //   "parol": "c4ca4238a0b923820dcc509a6f75849b",
  //   "token": "d29f9e1e2a18493bec070b280c2b5fff",
  //   "tokentime": "1672230833",
  //   "rol": "admin",
  //   "fio": "Toshmatov Mirkomil",
  //   "balans": "1000",
  //   "pasport": "AB3211892"

  return (
    <>
      <div className={css.WorkerTableBlock}>
        <table className={css.WorkerTable}>
          <thead>
            <tr className={css.WorkerTableTh}>
              <th>ID</th>
              <th>Ismi</th>
              <th>Telefon raqami</th>
              <th>Mansabi</th>
              <th>Login</th>
              <th style={{ width: 100 }}>Amallar</th>
            </tr>
          </thead>
          <tbody>{infoState}</tbody>
        </table>
      </div>
    </>
  );
};
