import React, { useRef, useState } from "react";
import { configs } from "config";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import api from "http";
import swal from "sweetalert";
import "../style/authorizationLayout.css";
import Loding from "Components/Loading/Loding";

export const AuthorizationLayout = () => {
  const [loading, setLoading] = useState(false);
  const signIn = useSignIn();
  const navigate = useNavigate();

  const LogRef = useRef();
  const ParolRef = useRef();

  const handleLogIn = (e) => {
    setLoading(true);
    e.preventDefault();
    const login = LogRef.current.value;
    const password = ParolRef.current.value;

    api
      .post("login", {
        login,
        password,
      })
      .then((res) => {
        // console.log("login", res);
        if (
          res.data.user.rol === configs.ad ||
          res.data.user.rol === configs.mng ||
          res.data.user.rol === configs.prog ||
          res.data.user.rol === configs.sk
        ) {
          if (
            signIn({
              token: res.data.access_token,
              expiresIn: res.data.expires_in,
              tokenType: res.data.token_type,
              authState: res.data.user,
            })
          ) {
            swal({
              title: `Xush kelibsiz`,
              text: `${res.data.user.fio}`,
              icon: "success",
              button: "ok",
            });
            navigate("/");
          } else {
            swal({
              title: "Авторизуйтесь или проверте логин и пароль!!!",
              icon: "warning",
              button: "Ок",
            });
            navigate("/login");
          }
        }
      })
      .catch((err) => {
        swal({
          title: "Ошибка!!!",
          icon: "error",
          button: "Ок",
        });
      })
      .finally((err) => {
        setLoading(false);
      });
  };

  return (
    <main className="login-form">
      <div className="login__mini-block">
        <span>Visola</span>
        {loading ? (
          <Loding />
        ) : (
          <form onSubmit={handleLogIn}>
            <div>
              <input
                className="login-form-nput"
                type="text"
                placeholder="login"
                ref={LogRef}
              />
            </div>
            <div>
              <input
                className="login-form-nput"
                type="password"
                placeholder="parol"
                ref={ParolRef}
              />
            </div>
            <div>
              <button className="login-form-btn" type="submit">
                Kirish
              </button>
            </div>
          </form>
        )}
      </div>
    </main>
  );
};
