import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import CategoryState from "./Provider/Category/CategoryState";
import { ProductState } from "stor/product/ProductState";
import { StockNewProductState } from "stor/stock/StockNewProductState";
import UserState from "stor/user/UserState";
import StockIssuanceState from "stor/stock/StockIssuanceState";
import DeliveryBalansGoodsState from "stor/stock/DeliveryBalansGoodsState";
import { AuthorizationLayout } from "Layout/AuthorizationLayout";
import ShopState from "stor/shops/ShopState";
import SmsState from "stor/sms/SmsState";
import App from "App";
import "antd/dist/reset.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ConfigProvider locale={ruRU}>
    <AuthProvider authType="localstorage" authName="_auth">
      <BrowserRouter>
        <ShopState>
          <UserState>
            <ProductState>
              <CategoryState>
                <StockNewProductState>
                  <StockIssuanceState>
                    <DeliveryBalansGoodsState>
                      <SmsState>
                        <Routes>
                          <Route
                            path="/*"
                            element={
                              <RequireAuth loginPath="/login">
                                <App />
                              </RequireAuth>
                            }
                          />
                          <Route path="*" element={<h1>Opssss....</h1>} />
                          <Route
                            path="/login"
                            element={<AuthorizationLayout />}
                          />
                        </Routes>
                      </SmsState>
                    </DeliveryBalansGoodsState>
                  </StockIssuanceState>
                </StockNewProductState>
              </CategoryState>
            </ProductState>
          </UserState>
        </ShopState>
      </BrowserRouter>
    </AuthProvider>
  </ConfigProvider>
);

// REACT_APP_DB_URL = https://visola-aziz.uz/api
