import React from "react";
import { NavLink } from "react-router-dom";
import "./skladLayout.css";
import { useAuthUser } from "react-auth-kit";
import { HeaderExit } from "Components/Headers/HeaderExit";
export default function Header() {
  const auth = useAuthUser();
  return (
    <header className="sklad__header">
      <div className="headet__user-name">{auth().fio}</div>
      <nav className="header__navigate">
        <ul className="header__navigare-list">
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navigation-Item-Active" : "navigation-Item"
              }
              to={"/"}
            >
              Kunlik maxsulotlar
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navigation-Item-Active" : "navigation-Item"
              }
              to={"finished-goods-warehouse"}
            >
              Skladga krim
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? "navigation-Item-Active" : "navigation-Item"
              }
              to={"stock-issuance"}
            >
              Sklatdan chiqim
            </NavLink>
          </li>
        </ul>

        <div>
          <HeaderExit />
        </div>
      </nav>
    </header>
  );
}
