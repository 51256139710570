import { useEffect, useReducer, useState } from "react";
import productReduser from "reduser/productReduser";
import { useAuthUser } from "react-auth-kit";
import { Stock } from "Context/context";
import { useForm } from "antd/es/form/Form";
import { useProduct } from "stor/product/useProduct";
import useLocalStorage from "hook/useLocalStorage";
import swal from "sweetalert";
import api from "http";
import { ADD_PRODUCT, DELETE_PRODUCT, DELETE_ARR } from "types/types";
import {
  ATTENTION,
  ERROR,
  NO,
  OK,
  OOPS,
  SUCCESS,
  SUCCESSOK,
  SWAL_INFO,
  SWAL_OKTEXT,
  WARNING,
} from "constant/constants";

export function StockNewProductState({ children }) {
  const auth = useAuthUser();
  const [form] = useForm();
  const { product } = useProduct();
  const [dataList, setDataList] = useLocalStorage([], "list");
  const [storage, setStorage] = useLocalStorage([], "fetchArr");
  const [state, dispatch] = useReducer(productReduser, storage);
  const [product_id, setProduncts] = useState("");
  const [count, setCount] = useState("");
  const [loading, setLoading] = useState(false);
  const [dayDataLoading, setDayDataLoadin] = useState(false);
  const [dailyData, setDailyData] = useState([]);

  function onProduct() {
    dispatch({
      type: ADD_PRODUCT,
      product_id,
      count,
    });
    addListProduct(product_id, count);
    setProduncts("");
    setCount("");
  }

  function addListProduct(value, count) {
    const productList = product.find((item) => item.id === value);
    const oneObject = dataList.find((item) => item.id === value);
    if (oneObject?.id === value) {
      dataList.forEach((item, index) => {
        if (item.id === value) {
          dataList[index].count += count;
        }
      });
      setDataList([...dataList]);
    } else {
      setDataList([
        ...dataList,
        { id: productList.id, name: productList.name, count },
      ]);
    }
  }

  function onDelete(key) {
    dispatch({
      type: DELETE_PRODUCT,
      id: key,
    });
    onDeleteList(key);
  }

  function onDeleteList(key) {
    setDataList((list) => list.filter((item) => item.id !== key));
  }

  function postProduct() {
    setLoading(true);
    swal({
      title: ATTENTION,
      text: SWAL_INFO,
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post("krim-add", { products: state, user_id: auth().id })
          .then(function (res) {
            if (res.status !== 200) {
              swal({
                title: OOPS,
                icon: ERROR,
                button: OK,
              });
            } else if (res.status === 200) {
              dispatch({
                type: DELETE_ARR,
              });
              setDataList([]);
              swal({
                title: SUCCESSOK,
                text: res.data.message,
                icon: SUCCESS,
                button: OK,
              });
            }
          })
          .catch(function (error) {
            swal({
              title: OOPS,
              text: error.message,
              icon: ERROR,
              button: OK,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else setLoading(false);
    });
  }

  function onDayStockProduct(value) {
    setDayDataLoadin(true);
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    //     console.log(value);
    api
      .post("krim-daily", value)
      .then((res) => {
        if (res.status === 200) {
          setDailyData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDayDataLoadin(false);
      });
  }

  // ежедневно добавленные продукты для склад лайаута SkladDailyArrivalForm
  function stockDaySort(value) {
    setDayDataLoadin(true);
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    api
      .post("krim-daily", value)
      .then((res) => {
        if (res.status === 200) {
          setDailyData(res.data);
          if (res.data.length === 0) {
            swal({
              title: "Bu foydalanuvchi maxsulot kiritmagan",
              icon: ERROR,
              button: OK,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDayDataLoadin(false);
      });
  }

  useEffect(() => {
    setStorage(state);
  }, [setStorage, state]);

  return (
    <Stock.Provider
      value={{
        postProduct,
        form,
        loading,
        onProduct,
        setProduncts,
        setCount,
        product_id,
        count,
        onDelete,
        dataList,
        state,
        onDayStockProduct,
        stockDaySort,
        dayDataLoading,
        dailyData,
      }}
    >
      {children}
    </Stock.Provider>
  );
}
