import React, { useState, useEffect } from "react";
import { ShopNewAdd } from "../shop/ShopNewAdd";
import { ShopList } from "../shop/ShopList";
import { TabsProvider } from "../../Provider/StoreProvider";
import { ShopSerch } from "./ShopSerch";
import { Typography } from "antd";
import swal from "sweetalert";
import Loding from "../Loading/Loding";
import api from "http";
const { Title } = Typography;

export const Shop = () => {
  // state для получения название городов
  const [cities, setCities] = useState([]);
  // state для получения название районов
  const [zion, setZion] = useState([]);
  // state для получения всех магазинов
  const [totalShop, setTotalShop] = useState([]);
  // state для загрузки
  const [isLoading, setIsLoading] = useState(false);

  // получаем название городов
  const GetViloyat = () => {
    api
      .post("regions")
      .then(function (res) {
        setCities(res.data);
        //  console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // получаем название районов
  const GetDistricts = () => {
    api
      .post("districts")
      .then(function (res) {
        setZion(res.data);
        //  console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // получаем данные магазинов
  const GetTotalShop = () => {
    api
      .post("shops-get")
      .then(function (res) {
        setTotalShop(res.data);
        setIsLoading(true);
        //   console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // dukonlar-delete.php
  useEffect(() => {
    GetViloyat();
    GetDistricts();
    GetTotalShop();
    // eslint-disable-next-line
  }, []);

  // удаляем данные магазинов
  const DeleteShop = (key) => {
    swal({
      title: "Ishonchingiz komilmi",
      // text: `Таблица будет удалён со всеми данными!`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post(`shop-del/${key}`)
          .then(function (res) {
            swal({
              title: `Ok`,
              icon: "success",
              button: "Ок",
            });
            GetTotalShop();
          })
          .catch(function (error) {
            swal({
              title: `${error}`,
              icon: "warning",
              button: "Ок",
            });
          });
      }
    });
  };

  const [filtered, setFiltered] = useState([]);

  // функцыя для поиска данных

  useEffect(
    (_) => {
      setFiltered(totalShop);
    },
    [totalShop]
  );

  const searchPoisk = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = totalShop;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.name.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = totalShop;
    }
    setFiltered(newList);
  };

  return (
    <TabsProvider.Provider value={GetTotalShop}>
      <div>
        <Title level={4}>Do'konlar ro'yhati</Title>
        <div style={Styles}>
          <ShopNewAdd cities={cities} zion={zion} />
          <ShopSerch {...{ searchPoisk }} />
        </div>
        {isLoading === false ? (
          <Loding />
        ) : (
          <ShopList
            totalShop={filtered}
            cities={cities}
            zion={zion}
            {...{ DeleteShop }}
          />
        )}
      </div>
    </TabsProvider.Provider>
  );
};

const Styles = {
  padding: "15px 0px",
  display: "flex",
  justifyContent: "space-between",
};
