import React from "react";
import { ModalCenter } from "../ModalComponent/ModalCenter";
import { AiFillPrinter } from "react-icons/ai";
import css from "../Order/StyleOrders.module.css";
import img from "../../img/192x192.png";
import { numberFormat } from "../../util/NumberFormat";
import Print from "../../util/Print";
import dateStringFormat from "../../util/dateStringFormat";

export const OrdersPrint = ({ ordersData, items }) => {
  // console.log("ordersData", ordersData);

  const PrintTableHeader = (
    <>
      <h3>Накладной №: {ordersData.id}.</h3>
      <div className={css.OrdersTableDate}>
        <span>Дата заказа: {dateStringFormat(ordersData.sana)}.</span>
        <span>Дата отправки: {new Date().toLocaleDateString()}</span>
      </div>
      <table className={css.OrdersDetailedTable}>
        <tbody>
          <tr>
            <td>Отправитель:</td>
            <td>Visola-Aziz</td>
          </tr>
          <tr>
            <td>Получатель:</td>
            <td>{ordersData.d_name}</td>
          </tr>
          <tr>
            <td>Адрес:</td>
            <td>{ordersData.manzil}</td>
          </tr>
          <tr>
            <td>Телефон:</td>
            <td>{ordersData.telefon}</td>
          </tr>
          <tr>
            <td>Долг:</td>
            <td>{numberFormat(ordersData.d_balans)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const PrintTable = (
    <table className={css.OrdersDetailedTable}>
      <thead>
        <tr className={css.OrdersDetailedTr}>
          <th>№</th>
          <th>Наименование</th>
          <th>Количество</th>
          <th>Цена</th>
          <th>Сумма</th>
        </tr>
      </thead>
      <tbody>
        {items.map((i, index) => (
          <tr key={i.id} className={css.OrdersDetailedTr}>
            <td className={css.OrdersDetailedTd}>{index + 1}</td>
            <td className={css.OrdersDetailedTd} style={{ fontSize: 12 }}>
              {i.nomi}
            </td>
            <td className={css.OrdersDetailedTd}>{i.soni}</td>
            <td className={css.OrdersDetailedTd}>{numberFormat(i.price)}</td>
            <td className={css.OrdersDetailedTd}>{numberFormat(i.summa)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2">Сумма заказа:</td>
          <td>{numberFormat(ordersData.summa)}</td>
        </tr>
        <tr>
          <td colSpan="2">Скидка:</td>
          <td>{ordersData.chegirma}%</td>
        </tr>
        <tr>
          <td colSpan="2">Сумма к оплате:</td>
          <td>{numberFormat(ordersData.total_skidka)}</td>
        </tr>
      </tfoot>
    </table>
  );

  return (
    <ModalCenter name={<AiFillPrinter style={{ fontSize: 18 }} />}>
      <Print>
        <div className={css.BlockTableBrint}>
          <div>
            <div className={css.BlockTableImgBlock}>
              <img src={img} alt="logo" className={css.BlockTableImg} />
            </div>
            <div>{PrintTableHeader}</div>
            <div>{PrintTable}</div>
            <div style={{ paddingTop: 15 }}>
              <span>Отправил________________________</span>
              <span>Получил_________________________</span>
            </div>
            <div style={{ paddingTop: 15 }}>
              <p>
                Агент: {ordersData.agent_name} {ordersData.ag_tel}
              </p>
              <p>
                Доставщик: {ordersData.eltuvchi_name} {ordersData.eltuvchi_tel}
              </p>
            </div>
            <p style={{ fontSize: 13 }}>
              Адрес: Самаркандская обл. Самаркандский район, махалля Кусахо,
              ул.Х.Гиясов 7-дом, телефон: +998(90)100-48-88
            </p>
          </div>
          <div>
            <div className={css.BlockTableImgBlock}>
              <img src={img} alt="logo" className={css.BlockTableImg} />
            </div>
            <div>{PrintTableHeader}</div>
            <div>{PrintTable}</div>
            <div style={{ paddingTop: 15 }}>
              <span>Отправил________________________</span>
              <span>Получил_________________________</span>
            </div>
            <div style={{ paddingTop: 15 }}>
            <p>
                Агент: {ordersData.agent_name} {ordersData.ag_tel}
              </p>
              <p>
                Доставщик: {ordersData.eltuvchi_name} {ordersData.eltuvchi_tel}
              </p>
            </div>
            <p style={{ fontSize: 13 }}>
              Адрес: Самаркандская обл. Самаркандский район, махалля Кусахо,
              ул.Х.Гиясов 7-дом, телефон: +998(90)100-48-88
            </p>
          </div>
        </div>
      </Print>
    </ModalCenter>
  );
};
