import React, { useState } from "react";
import { Table, Button, Row, Col, Modal, Form } from "antd";
import Loding from "../Loading/Loding";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import ShopEdit from "./ShopEdit";
import { numberFormat } from "util/NumberFormat";
import round from "util/round";

export const ShopList = ({ totalShop, DeleteShop, cities, zion }) => {
  const [form] = Form.useForm();
  const [top] = useState("topLeft");
  const New = totalShop;
  console.log("New", New);

  // state для модалки ShopEdit
  const [modalOpen, setModalOpen] = useState(false);

  const closeGotovModal = async () => {
    setModalOpen(false);
    form.setFieldsValue({
      name: "",
      fio: "",
      manzil: "",
      telefon: "",
      tuman_id: "",
      viloyat_id: "",
      eltuvchi_id: "",
      agent_id: "",
      balans: "",
    });
  };
  // передаём данные в модалку для формы
  const [shopData, setShopData] = useState(null);
  const ClientEdit = async (key) => {
    const data = await New.find((i) => i.id === key);
    setShopData(data);
    setModalOpen(true);
    form.setFieldsValue({
      name: data?.name,
      fio: data?.fio,
      manzil: data?.manzil,
      telefon: data?.telefon,
      tuman_id: data?.tuman_id,
      viloyat_id: data?.viloyat_id,
      eltuvchi_id: data?.eltuvchi_id,
      agent_id: data?.agent_id,
      balans: data?.balans,
    });
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      width: 20,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Do'kon nomi",
      dataIndex: "name",
      width: 250,
    },
    {
      title: "Egasi",
      dataIndex: "fio",
    },
    {
      title: "Telefoni",
      dataIndex: "telefon",
    },

    {
      title: "Viloyat",
      dataIndex: "viloyat_name",
    },
    {
      title: "Tuman",
      dataIndex: "tuman_name",
    },
    {
      title: "Manzili",
      dataIndex: "manzil",
    },
    {
      title: "Balans",
      dataIndex: "balans",
      editable: true,
      render: (_, record) => {
        return numberFormat(round(record.balans, 2));
      },
    },
    {
      title: "Agent",
      dataIndex: "agent_name",
    },
    {
      title: "Eltuvchi",
      dataIndex: "eltuvchi_name",
    },

    {
      title: "Amallar",
      dataIndex: "operation",
      width: 150,

      render: (_, record) => {
        return (
          <div>
            <Row>
              <Col span={12}>
                <Button onClick={() => ClientEdit(record.id)}>
                  <BiEditAlt />
                </Button>
              </Col>
              <Col span={12}>
                <Button danger onClick={() => DeleteShop(record.id)}>
                  <RiDeleteBin5Line />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  const Style = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  let column = columns.map((item) => {
    return Object.assign(
      {
        sorter: (a, b) => {
          // a.dataIndex.length - b.dataIndex.length
          if (a[item.dataIndex] > b[item.dataIndex]) return 1;
          if (a[item.dataIndex] === b[item.dataIndex]) return 0;
          if (a[item.dataIndex] < b[item.dataIndex]) return -1;
        },
        sortDirections: ["descend", "ascend"],
      },
      item
    );
  });
  if (New.length === 0) {
    return (
      <div style={Style}>
        <Loding />
      </div>
    );
  }

  return (
    <>
      <Table
        rowKey={(row) => row.id}
        bordered
        dataSource={New}
        columns={column}
        rowClassName="editable-row"
        pagination={{
          position: [top],
          //  pageSize: "8",
        }}
        scroll={{
          x: 1300,
        }}
      />

      <Modal
        title="Dukon ma'lumotlarini taxrirlash"
        open={modalOpen}
        onCancel={closeGotovModal}
        footer={null}
        width={"50vw"}
      >
        <ShopEdit shopData={shopData} cities={cities} zion={zion} form={form} />
      </Modal>
    </>
  );
};

// balans: "50000";
// fio: "Ibrohim Bultakov";
// id: "3";
// locatsiya: "";
// manzil: "Pavarot";
// name: "Optovik2";
// qarz: "0";
// status: "active";
// telefon: "+998937286867";
// tuman_id: "1";
// viloyat_id: "7";
