import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import React from "react";
import { useStock } from "stor/stock/useStock";
import useUsers from "stor/user/useUsers";

const { Item } = Form;

export default function StockFilter() {
  const { user } = useUsers();
  const { onDayStockProduct } = useStock();

  const data = user.map((i) =>
    i.rol === "admin" || i.rol === "manager" || i.rol === "programist" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  return (
    <Form layout="vertical" onFinish={onDayStockProduct}>
      <Row>
        <Col span={12}>
          <Item
            label="Boshlanish sanasi"
            name="sana1"
            rules={[
              {
                required: true,
                message: "Sanani tanlang",
              },
            ]}
          >
            <DatePicker
              placement={"bottomRight"}
              placeholder="Boshlanish sanasi"
              style={{ width: "100%" }}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Tugash sanasi"
            name="sana2"
            rules={[
              {
                required: true,
                message: "Sanani tanlang",
              },
            ]}
          >
            <DatePicker
              placement={"bottomRight"}
              style={{ marginLeft: 5, width: "100%" }}
              placeholder="Tugash sanasi"
            />
          </Form.Item>
        </Col>
      </Row>
      <Item name="user_id" label="Masul xodim">
        <Select>
          <Select.Option value={0}>Barchasi</Select.Option>
          {data}
        </Select>
      </Item>
      <Item>
        <Button htmlType="submit" type="primary">
          Saralash
        </Button>
      </Item>
    </Form>
  );
}
