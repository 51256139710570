import dateFormat from "dateformat";

export default function filterFormDate() {
  let d = new Date();
  d.setDate(d.getDate() - 1);
  const date1 = dateFormat(d, "yyyy-mm-dd");
  let d2 = d.setDate(d.getDate() + 2);
  const date2 = dateFormat(d2, "yyyy-mm-dd");

  return {
    dateOne: date1,
    dateTwo: date2,
  };
}
