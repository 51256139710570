import React from "react";
import { Table, Typography } from "antd";
// import LodingText from "../Loading/LodingText";
import { numberFormat } from "util/NumberFormat";

const { Title } = Typography;

const columns = [
  {
    title: "№",
    dataIndex: "id",
    width: 20,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  {
    title: "Nomi",
    dataIndex: "nomi",
  },
  {
    title: "Soni",
    dataIndex: "soni",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.soni - b.soni,
  },
  {
    title: "Narxi",
    dataIndex: "narxi",
    render: (_, record) => {
      return numberFormat(record.narxi);
    },
  },
  {
    title: "Summa",
    dataIndex: "summa",
    render: (_, record) => {
      let Summa = record.soni * record.narxi;
      return numberFormat(Summa);
    },
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
};

export const OrdersDeliverTable = ({
  interval,
  componentRef,
  agent,
  dateOne,
  date2,
  isLoding,
}) => {
  //   console.log(agent);
  const DataAgent = agent.length !== 0 ? agent.map((i) => i.fio) : "Barchasi";

  return (
    <>
      <div ref={componentRef}>
        <div>
          <Title level={2}>Eltuvchi: {DataAgent} </Title>
          <span>
            {dateOne} - {date2}
          </span>
        </div>
        <Table
          rowKey={(row) => row.pr_id}
          columns={columns}
          dataSource={interval}
          onChange={onChange}
          pagination={false}
          style={{ fontSize: 20 }}
          loading={isLoding}
        />
      </div>
    </>
  );
};
