import React from "react";
import { DatePicker, Form, Button, Row, Col } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
function ActSwerkiSort({ ActSort }) {
  return (
    <Form onFinish={ActSort}>
      <Row>
        {/* <Col flex={2}>
          <Form.Item name="yil_id" placeholder="Дата">
            <DatePicker
              style={{ width: 200 }}
              placeholder="Yil"
              picker="year"
              locale={locale}
            />
          </Form.Item>
        </Col> */}
        <Col flex={2}>
          <Form.Item name="monthStart">
            <DatePicker
              style={{ width: 200 }}
              placeholder="Boshlang'ich oy"
              picker="month"
              locale={locale}
            />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item name="monthEnd">
            <DatePicker
              style={{ width: 200 }}
              placeholder="Tugash oy"
              picker="month"
              locale={locale}
            />
          </Form.Item>
        </Col>
        <Col flex={2}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Saralash
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ActSwerkiSort;
