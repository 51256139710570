import React, { useContext } from "react";
import { Table, Button, Tooltip, Tag } from "antd";
import { AiFillDelete, AiOutlineDownload } from "react-icons/ai";
import swal from "sweetalert";
import LodingText from "../Loading/LodingText";
import { TabsProvider } from "../../Provider/StoreProvider";
import css from "./StyleOrders.module.css";
import { numberFormat } from "util/NumberFormat";
import api from "http";
import dateStringFormat from "util/dateStringFormat";

// import useSound from "use-sound";

// import iphone from "../../Sound/iphone.mp3";
// let Arrdata = [];

export const OrderNewList = ({ filtered, componentRef }) => {
  const GetOrders = useContext(TabsProvider);
  // sound biblioteka
  //   const [play] = useSound(iphone);

  //   useEffect(
  //     (_) => {
  //       if (Arrdata.length < filtered.length) {
  //         return play;
  //       }
  //       Arrdata.length = 0;
  //     },
  //     // eslint-disable-next-line
  //     [filtered]
  //   );
  //   console.log("filtered", filtered);
  // удаляем заказ
  const DeleteOrders = (id) => {
    // const data = { id: id, token: token };

    // const config = {
    //   method: "post",
    //   url: `${url}/orders-delete.php`,
    //   headers: {
    //     "Content-Type": "text/plain",
    //   },
    //   data: data,
    // };
    swal({
      title: "Ishonchingiz komilmi",
      // text: `Таблица будет удалён со всеми данными!`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post("orders-delete", { id: id })
          .then(function (res) {
            GetOrders();
            swal({
              title: `${res.data.xabar}`,
              icon: "success",
              button: "Ок",
            });
          })
          .catch(function (error) {
            swal({
              title: `${error}`,
              icon: "warning",
              button: "Ок",
            });
            GetOrders();
          });
      }
    });
  };

  // подтвеждаем заказ
  const OrdersConfirm = (id) => {
    swal({
      title: "Ishonchingiz komilmi",
      // text: `Таблица будет удалён со всеми данными!`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post("order-confirm-manager", { id: id })
          .then(function (res) {
            GetOrders();
            swal({
              title: `${res.data.xabar}`,
              icon: "success",
              button: "Ок",
            });
          })
          .catch(function (error) {
            swal({
              title: `${error}`,
              icon: "warning",
              button: "Ок",
            });
            GetOrders();
          });
      }
    });
  };
  // удаляем один элемент заказа
  const DeleteItems = (val) => {
    // const data = JSON.stringify({
    //   token: localStorage.getItem("DataToken"),
    //   id: val,
    // });
    // //  console.log("delete item", data);
    // const config = {
    //   method: "post",
    //   url: `${url}/orders-item-delete.php`,
    //   headers: {
    //     "Content-Type": "text/plain",
    //   },
    //   data: data,
    // };
    swal({
      title: "Ishonchingiz komilmi",
      text: `Buyurtmadan maxsulotni o'chiramizmi??!`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post("orders-item-delete", { id: val })
          .then(function (res) {
            GetOrders();
            swal({
              title: `${res.data.xabar}`,
              icon: "success",
              button: "Ок",
            });
          })
          .catch(function (error) {
            swal({
              title: `${error}`,
              icon: "warning",
              button: "Ок",
            });
            GetOrders();
          });
      }
    });
  };
  // изменяем один элемент заказа
  //   const UpdateItems = (val) => {
  //     //  console.log("val", val);
  //   };

  const columns = [
    {
      title: "Sana",
      dataIndex: "sana",
      key: "sana",
      render: (_, rec) => {
        return dateStringFormat(rec.sana);
      },
    },
    {
      title: "Do'kon nomi",
      dataIndex: "dukon_name",
      key: "dukon_name",
    },
    {
      title: "Summa",
      dataIndex: "summa",
      key: "summa",
      render: (_, record) => {
        return numberFormat(record.summa);
      },
    },
    {
      title: "Chegirma",
      dataIndex: "chegirma",
      key: "chegirma",
    },
    {
      title: "Qolgan summa",
      dataIndex: "total_skidka",
      key: "total_skidka",
      render: (_, record) => {
        return numberFormat(record.total_skidka);
      },
    },

    {
      title: "Mas'ul agent",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Xolati",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status === "new") {
          return <Tag color="#f50">Yangi buyurtma</Tag>;
        } else {
          return <Tag color="#87d068">Qabul qilingan</Tag>;
        }
      },
    },
    {
      title: "Amallar",
      key: "operation",
      className: "orders__new-amallar",
      render: (_, record) => (
        <>
          {" "}
          {/* <Tooltip title="Buyurtmani o'zgartirish" color={"#ffa940"}>
            <Button
              type="primary"
              shape="circle"
              icon={<AiFillEdit />}
              style={{ marginRight: 5, backgroundColor: "#ffa940" }}
            />
          </Tooltip> */}
          <Tooltip title="Buyurtmani qabul qilish" color={"#2db7f5"}>
            <Button
              type="primary"
              shape="circle"
              icon={<AiOutlineDownload />}
              onClick={() => OrdersConfirm(record.id)}
              style={{ marginRight: 5 }}
            />
          </Tooltip>
          <Tooltip title="Buyurtmani o'chirish" color={"#f50"}>
            <Button
              type="primary"
              danger
              shape="circle"
              icon={<AiFillDelete />}
              onClick={() => DeleteOrders(record.id)}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  if (filtered.length === 0) {
    return <LodingText />;
  }

  return (
    <div ref={componentRef}>
      <Table
        bordered
        rowClassName="editable-row"
        rowKey={(row) => row.id}
        columns={columns}
        //   pagination={{
        //     position: [top],
        //     //  pageSize: "8",
        //   }}
        pagination={false}
        scroll={{
          x: 1124,
        }}
        dataSource={filtered}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <table className={css.OrdersDetailedTable}>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Nomi</th>
                    <th>Soni</th>
                    <th>Narxi</th>
                    <th>Summa</th>
                    <th>Amallar</th>
                  </tr>
                </thead>
                <tbody>
                  {record.products.map((i, index) => (
                    <tr key={i.id} className={css.OrdersDetailedTr}>
                      <td className={css.OrdersDetailedTd}>{index + 1}</td>
                      <td className={css.OrdersDetailedTd}>{i.nomi}</td>
                      <td className={css.OrdersDetailedTd}>{i.soni}</td>
                      <td className={css.OrdersDetailedTd}>
                        {numberFormat(i.price)}
                      </td>
                      <td className={css.OrdersDetailedTd}>
                        {numberFormat(i.summa)}
                      </td>
                      <td className={css.OrdersDetailedTd}>
                        {/* <Tooltip title="O'zgartirish">
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<AiFillEdit />}
                            style={{ marginRight: 5 }}
                            onClick={() => UpdateItems(i.id)}
                          />
                        </Tooltip> */}
                        <Tooltip title="O'chirish">
                          <Button
                            type="primary"
                            danger
                            shape="circle"
                            icon={<AiFillDelete />}
                            onClick={() => DeleteItems(i.id)}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p>{record.izoh}</p>
            </>
          ),
          //  defaultExpandedRowKeys: ["3"],
        }}
      />
    </div>
  );
};
