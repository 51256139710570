import React from "react";
import { Table } from "antd";
import Loding from "../Loading/Loding";
import { numberFormat } from "../../util/NumberFormat";

export const OrdersReportHisTulov = ({ tulov }) => {
  let totalCash = 0;
  let Karta = 0;
  let Plastik = 0;
  let Naqd = 0;
  tulov.forEach(({ karta, summa, plastik }) => {
    Karta += Number(karta);
    Plastik += Number(plastik);
    Naqd += Number(summa);
    totalCash = Karta + Plastik + Naqd;
  });
  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 20,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "To'lov sanasi",
      dataIndex: "tulov_sana",
      key: "tuloca_sana",
    },
    {
      title: "Naqd",
      dataIndex: "summa",
      key: "summa",
      render: (_, record) => {
        return numberFormat(record.summa);
      },
    },
    {
      title: "Karta",
      dataIndex: "karta",
      key: "karta",
      render: (_, record) => {
        return numberFormat(record.karta);
      },
    },
    {
      title: "plastik",
      dataIndex: "plastik",
      key: "plastik",
      render: (_, record) => {
        return numberFormat(record.plastik);
      },
    },
    {
      title: "Masul",
      dataIndex: "olgan",
      key: "olgan",
    },
  ];

  if (tulov.length === 0) {
    return <Loding />;
  }
  return (
    <div>
      <p>Jami summa: {numberFormat(totalCash)}</p>
      <Table
        rowKey={(row) => row.id}
        bordered
        dataSource={tulov}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{
          x: 1124,
        }}
      />
    </div>
  );
};
