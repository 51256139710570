import React, { useRef } from "react";
import { Table, Button, Typography } from "antd";
import ReactToPrint from "react-to-print";
import LodingText from "../../Loading/LodingText";
import { numberFormat } from "util/NumberFormat";
import dateStringFormat from "util/dateStringFormat";

const { Title } = Typography;
//   agent_id: "21";
//   agent_name: "Баратова Фируза";
//   chegirma: "";
//   dukon_id: "603";
//   dukon_name: "Диёрбек Маркет АзизБозор";
//   id: "996";
//   products: [{…}, {…}]
//   sana: "09-02-2023";
//   status: "new";
//   summa: "401000";
//   total_skidka: "401000";
const columns = [
  {
    title: "№",
    dataIndex: "id",
    width: 20,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  {
    title: "Sana",
    dataIndex: "sana",
    width: 120,
    render: (_, rec) => {
      return dateStringFormat(rec.sana);
    },
  },
  {
    title: "Do'kon nomi",
    dataIndex: "dukon_name",
    width: 300,
  },
  {
    title: "Buyurtma summasi",
    dataIndex: "summa",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.summa - b.summa,
    render: (_, record) => {
      return numberFormat(record.summa);
    },
  },
  {
    title: "Chegirma",
    dataIndex: "chegirma",
  },
  {
    title: "Chegirmadan keyingi summa",
    dataIndex: "total_skidka",
    render: (_, record) => {
      return numberFormat(record.total_skidka);
    },
  },
  {
    title: "Masul agent",
    dataIndex: "agent_name",
    width: 200,
  },
];
export const OrdersSingleAgentTable = ({ interval }) => {
  const componentRef = useRef();
  //   console.log("interval", interval);

  const onChange = (pagination, filters, sorter, extra) => {
    //   console.log("params", pagination, filters, sorter, extra);
  };
  let Total = 0;
  interval.forEach(({ total_skidka }) => {
    Total += Number(total_skidka);
  });
  return (
    <>
      {" "}
      {interval.length === 0 ? (
        <LodingText />
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ReactToPrint
              trigger={() => (
                <Button type="primary" danger>
                  Chop etish
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>

          <div ref={componentRef}>
            <Title level={4}>
              Jami summa (chegirmalar hisobga olingan){"\xa0\xa0"}{" "}
              {numberFormat(Total)}
            </Title>
            <Table
              rowKey={(row) => row.id}
              columns={columns}
              dataSource={interval}
              onChange={onChange}
              pagination={false}
              style={{ fontSize: 20 }}
            />
          </div>
        </>
      )}
    </>
  );
};
