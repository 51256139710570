import { Select } from "antd";
import React from "react";

export default function ProductCategorySort({ category, handleCategory }) {
  return (
    <Select
      placeholder="Toifalar"
      allowClear
      onSelect={handleCategory}
      style={{
        width: 250,
        marginRight: 15,
      }}
    >
      <Select.Option value="0">Barchasi</Select.Option>
      {category.map((i) => (
        <Select.Option key={i.id} value={i.id}>
          {i.name}
        </Select.Option>
      ))}
    </Select>
  );
}
