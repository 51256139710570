import { SHOP_BALANS, SHOP_NAME } from "Components/Sms/smsConstants";
import { Sms } from "Context/context";
import { ERROR, OK, OOPS, SUCCESS, SUCCESS_SMS } from "constant/constants";
import api from "http";
import React, { useEffect, useState } from "react";
import useShops from "stor/shops/useShops";
import swal from "sweetalert";
import { deleteSimbolFromNumber } from "util/deleteSimbolFromNumber";

export default function SmsState({ children }) {
  const { shop } = useShops();
  const [value, setValue] = useState("");
  const [shopId, setShopId] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  function onAllShopCheck(e) {
    if (e.target.checked === true) {
      const dataShop = shop.map((item) => item.id);
      setShopId(dataShop);
    } else setShopId([]);
  }

  function onAllDebtors(e) {
    if (e.target.checked) {
      const dataShop = shop.filter(
        (item) => item.balans < -10000 && item.telefon !== ""
      );

      //       console.log("data", dataShop);
      const finishData = dataShop.map((item) => item.id);
      setShopId(finishData);
    } else setShopId([]);
  }

	function onSelect(shop_id) {
	  
		setShopId(shop_id);
		
  }

  function onSendSms() {
    if (value.length !== 0) {
      let sendData = shopId.map((sendShopId) => {
        let oneShop = shop.find((sh) => sh.id === sendShopId);

        return {
          user_sms_id: oneShop.id,
          to: deleteSimbolFromNumber(oneShop.telefon),
          text: value
            .replaceAll(SHOP_NAME, oneShop.name)
            .replaceAll(SHOP_BALANS, oneShop.balans),
        };
      });

      //       console.log(sendData);
      api
        .post("sendSms", sendData)
        .then((res) => {
          //   console.log(res);
          if (res.status === 200) {
            swal({
              title: SUCCESS_SMS,
              icon: SUCCESS,
              button: OK,
            });
          }
        })
        .catch((err) => {
          swal({
            title: OOPS,
            icon: ERROR,
            button: OK,
          });
        });
    }
    setValue("");
  }

  function onClear(e) {
    console.log(e);
  }

  useEffect(() => {
    if (shopId.length !== 0) {
      setIsDisabled(false);
    } else setIsDisabled(true);

    if (value.length !== 0) {
      setButtonDisabled(false);
    } else setButtonDisabled(true);
  }, [shopId.length, value.length]);
  return (
    <Sms.Provider
      value={{
        onAllShopCheck,
        setValue,
        onSelect,
        onSendSms,
        onClear,
        value,
        isDisabled,
        buttonDisabled,
        onAllDebtors,
      }}
    >
      {children}
    </Sms.Provider>
  );
}
