import { Route, Routes } from "react-router-dom";
import { OrdersReportOrderSingle } from "../Components/OrdersHisobot/OrdersReportOrderSingle";
import { Orders } from "../Components/Order/Orders";
import { OrdersConfirmed } from "../Components/OrdersConfirmed/OrdersConfirmed";
import { OrdersItem } from "../Components/OrdersConfirmed/OrdersItem";
import { OrdersFilter } from "../Components/OrdersFilter/OrdersFilter";
import ActSwerkiWrapper from "../Components/OrdersHisobot/ActSverki/ActSwerkiWrapper";
import { OrdersDeliverFilter } from "../Components/OrdersDeliverFilter/OrdersDeliverFilter";
import { Workers } from "../Components/Users/Workers";
import { Product } from "../Components/Product/Product";
import { Shop } from "../Components/shop/Shop";
import { OrdersReport } from "../Components/OrdersHisobot/OrdersReport";
import { OrdersDaily } from "../Components/OrdersHisobot/OrdersDaily/OrdersDaily";
import { DebitKredit } from "../Components/OrdersHisobot/OrdersDebitKredit/DebitKredit";
import { OrdersReturn } from "../Components/OrdersReturn/OrdersReturn";
import CategorySetting from "Components/Setting/CategorySetting";
import StockProduct from "Components/stock/StockProduct";
import StockIssuance from "Components/stock/StockIssuance";
import SmsPage from "Components/Sms/SmsPage";
import OrdersDan from "Components/OrdersDan/OrdersDan";
import AllOrders from "Components/AllOrders/AllOrders";

export default function Router() {
  return (
    <Routes>
      <Route path="debtorsList" element={<OrdersReport />} />
      <Route path="debtorsList/:key" element={<OrdersReportOrderSingle />} />
      <Route path="orders" element={<Orders />} />
      <Route path="ordersConfirm" element={<OrdersConfirmed />} />
      <Route path="ordersConfirm/:id" element={<OrdersItem />} />
      <Route path="ordersFilter" element={<OrdersFilter />} />
      <Route path="actsverka" element={<ActSwerkiWrapper />} />
      <Route path="deliver" element={<OrdersDeliverFilter />} />
      <Route path="personal" element={<Workers />} />
      <Route path="product" element={<Product />} />
      <Route path="shop" element={<Shop />} />
      <Route path="orders-daily" element={<OrdersDaily />} />
      <Route path="debit-kredit" element={<DebitKredit />} />
      <Route path="return" element={<OrdersReturn />} />
      <Route path="category" element={<CategorySetting />} />
      <Route path="finished-goods-warehouse" element={<StockProduct />} />
      <Route path="stock-issuance" element={<StockIssuance />} />
      <Route path="sms" element={<SmsPage />} />
      <Route path="dan_order" element={<OrdersDan />} />
      <Route path="all_orders" element={<AllOrders />} />
    </Routes>
  );
}
