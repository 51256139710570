import React, { useState } from "react";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import swal from "sweetalert";
import api from "http";
// get-orders-singl-agent.php
const OrdersSingleAgentForm = ({ userData, setInterval, setIsLoding }) => {
  // запрос данных
  const IntervalData = (value) => {
    setIsLoding(true);
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    api
      .post("get-orders-singl-agent", value)
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          setInterval(res.data);
          setIsLoding(false);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          setIsLoding(false);
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        setIsLoding(false);
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };
  // выводим имена агентов
  const Agent = userData.map((i) =>
    i.rol === "agent" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  const [placement] = useState("bottomRight");
  return (
    <div>
      <Form
        name="time_related_controls"
        onFinish={IntervalData}
        layout="vertical"
      >
        <div>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Boshlang'ich vaqti"
                name="sana1"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={placement}
                  placeholder="Boshlang'ich vaqti"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tugash vaqti"
                name="sana2"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={placement}
                  style={{ marginLeft: 5, width: "100%" }}
                  placeholder="Tugash vaqti"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {" "}
            <Col span={18}>
              {" "}
              <Form.Item label="Agentni tanlang" name="agent_id">
                <Select>{Agent}</Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Ma'lumotlarni saralash">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 5, padding: 0, width: "100%" }}
                >
                  Saralash
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default OrdersSingleAgentForm;
