import React from "react";
import { UserNewAdd } from "../Users/UserNewAdd";
import { WorkerList } from "../Users/WorkerList";
import Loding from "../Loading/Loding";
import { Typography } from "antd";
import css from "./Users.module.css";
import useUsers from "stor/user/useUsers";
const { Title } = Typography;

export const Workers = () => {
  const { loading, user, onDeleteUser, onAddUser, form } = useUsers();

  if (loading === true) {
    return (
      <div className={css.UserNewLoding}>
        <Loding />
      </div>
    );
  }

  return (
    <>
      <Title level={4}>Hodimlar</Title>
      <div>
        <div style={{ paddingBottom: 15 }}>
          <UserNewAdd onAddUser={onAddUser} form={form} />
        </div>
        {loading ? (
          <Loding />
        ) : (
          <WorkerList user={user} onDeleteUser={onDeleteUser} />
        )}
      </div>
    </>
  );
};
