import { useRef, useState } from "react";
import { Product } from "Context/context";
import { useForm } from "antd/es/form/Form";
import api from "http";
import swal from "sweetalert";
import { deleteSimbolFromNumber } from "util/deleteSimbolFromNumber";
import {
  ARE_YOU_SURE,
  ATTENTION,
  ERROR,
  NO,
  NOSAVE,
  OK,
  OOPS,
  SUCCESS,
  SUCCESSOK,
  SWAL_INFO,
  SWAL_OKTEXT,
  WARNING,
} from "constant/constants";

export function ProductState({ children }) {
  const [form] = useForm();
  const RefPrice = useRef(null);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);

  function getProduct() {
    setLoading(true);
    api
      .post("products")
      .then(function (res) {
        if (res.status === 200) {
          setProduct(res.data);
        }
        //   console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }

  function addProduct(values) {
    setLoading(true);
    values.price = deleteSimbolFromNumber(RefPrice.current.input.value);

    swal({
      title: ATTENTION,
      text: SWAL_INFO,
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post(`products-add`, values)
          .then(function (res) {
            if (res.status === 200) {
              swal({
                title: SUCCESSOK,
                icon: SUCCESS,
                button: OK,
              });
              form.resetFields();
              getProduct();
            } else {
              swal({
                title: OOPS,
                text: NOSAVE,
                icon: ERROR,
                button: OK,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => setLoading(false));
      }
    });
  }

  function updateProduct(value) {
    setLoading(true);
    api
      .post(`product/${value.id}`, value)
      .then((res) => {
        getProduct();
        //   console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  function deleteProduct(key) {
    setLoading(true);
    swal({
      title: ARE_YOU_SURE,
      // text: `Таблица будет удалён со всеми данными!`,
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post(`product-del/${key}`)
          .then(function (res) {
            swal({
              title: SUCCESSOK,
              icon: SUCCESS,
              button: OK,
            });
            if (res.status === 200) {
              setProduct((product) =>
                product.filter((item) => item.id !== key)
              );
            }
          })
          .catch(function (error) {
            swal({
              title: `${error}`,
              icon: WARNING,
              button: OK,
            });
          })
          .finally(() => setLoading(false));
      } else setLoading(false);
    });
  }
  return (
    <Product.Provider
      value={{
        product,
        getProduct,
        loading,
        deleteProduct,
        updateProduct,
        RefPrice,
        form,
        addProduct,
      }}
    >
      {children}
    </Product.Provider>
  );
}
