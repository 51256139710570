import React from "react";
import { Table } from "antd";

export const DebitKreditTable = ({ state }) => {
  //   console.log("Table", state);
  const newArr = [];
  Object.entries(state).map(([key, value]) => newArr.push(value));
  //   console.log(newArr);
  const columns = [
    {
      title: "Sana",
      dataIndex: "sana",
    },
    {
      title: "Nomi",
      dataIndex: "dukon_name",
    },
    {
      title: "Buyurtma summasi",
      dataIndex: "total_skidka",
    },
    {
      title: "Qarzi",
      dataIndex: "qarz",
    },
    {
      title: "Qabul qilingan summa",
      dataIndex: "qabul_summa",
    },
  ];

  return (
    <>
      {newArr[0] !== 1 ? (
        <Table columns={columns} dataSource={newArr} pagination={false} />
      ) : (
        <h1>{state.xabar}</h1>
      )}
    </>
  );
};
