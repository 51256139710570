import React, { useState, useEffect } from "react";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import swal from "sweetalert";
import { ModalCenter } from "../../ModalComponent/ModalCenter";
import { Typography } from "antd";
import api from "http";
import filterFormDate from "util/filterFormDate";

const { Title } = Typography;

export const OrdersDailyForm = ({ userData, setDailyData, setIsLoding }) => {
  const date = filterFormDate();
  // запрос данных
  const IntervalData = (value) => {
    setIsLoding(true);
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
      dukon_id: value["dukon_id"] ?? 0,
    };
    console.log("value-1", value);
    api
      .post("get-daily-report", value)
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          //  console.log(res.data);
          setDailyData(res.data);
          setIsLoding(false);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  // запрос данных
  const IntervalDataEffect = (value) => {
    setIsLoding(true);
    const data = {
      sana1: date.dateOne,
      sana2: date.dateTwo,
      dukon_id: 0,
    };
    console.log("value", data);
    api
      .post("get-daily-report", data)
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          setDailyData(res.data);
          setIsLoding(false);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          swal({
            title: "Diqqat!!!",
            text: "Internet uzilish!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  useEffect(() => {
    IntervalDataEffect();
    // eslint-disable-next-line
  }, []);

  const [placement] = useState("bottomRight");

  return (
    <ModalCenter name={"Ma'lumotlarni saralash"}>
      <Title level={4}>Ma'lumotlarni saralash</Title>
      <Form
        name="time_related_controls"
        onFinish={IntervalData}
        layout="vertical"
      >
        <div>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                label="Boshlang'ich vaqti"
                name="sana1"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={placement}
                  placeholder="Boshlang'ich vaqti"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tugash vaqti"
                name="sana2"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={placement}
                  style={{ width: "100%" }}
                  placeholder="Tugash vaqti"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="dukon_id" label="Do'konni tanlang">
            <Select
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              // placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={userData.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 5 }}>
              Saralash
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ModalCenter>
  );
};
