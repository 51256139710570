import React from "react";
import { Select } from "antd";
import swal from "sweetalert";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import api from "http";

dayjs.extend(customParseFormat);

function ActSwerkiForm({ shop, newArr, shopSingle }) {
  const IntervalData = async (value) => {
    api
      .post(`aktsver?dukon=${value}`)
      .then((res) => {
        if (res.status === 200) {
          newArr(res.data);
        } else {
          swal({
            title: "Malumot topilmadi",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Select
      allowClear
      onSelect={IntervalData}
      onChange={shopSingle}
      showSearch
      style={{
        width: "100%",
      }}
      placeholder="Dukonlar nomi..."
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? "").includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      options={shop.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
    />
  );
}

export default ActSwerkiForm;
