import React, { useState } from "react";
import { Typography } from "antd";
import LodingText from "../../Loading/LodingText";
import { OrderAccounting } from "./OrderAccounting";
import { Card, Row, Col, Table, Modal } from "antd";
import { numberFormat } from "util/NumberFormat";

const { Title } = Typography;

// eltuvchi: "Алижон Доставщик";
// eltuvchi_id: "1414142";
// karta: "";
// naqd: "508800";
// plastik: "";
// qarz: "0";
// total: "508800";
const columns = [
  {
    title: "№",
    dataIndex: "nomer_zakaz",
    width: 20,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  {
    title: "Yuk xati №",
    dataIndex: "nomer_zakaz",
    width: 80,
  },
  {
    title: "Buyurtma sanasi",
    dataIndex: "sana",
  },
  {
    title: "Dukon nomi",
    dataIndex: "dukon_name",
  },
  {
    title: "Buyurtma summasi",
    dataIndex: "total",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.total - b.total,
    render: (_, record) => {
      return numberFormat(record.total);
    },
  },
  {
    title: "To'lov sanasi",
    dataIndex: "oplata",
  },
  {
    title: "To'lov turlari",
    children: [
      {
        title: "Naqd",
        dataIndex: "naqd",
        key: "naqd",
        render: (_, record) => {
          return numberFormat(record.naqd);
        },
      },
      {
        title: "Bank",
        dataIndex: "plastik",
        key: "plastik",
        render: (_, record) => {
          return numberFormat(record.plastik);
        },
      },
      {
        title: "Karta",
        dataIndex: "karta",
        key: "karta",
        render: (_, record) => {
          return numberFormat(record.karta);
        },
      },
      {
        title: "Qolgan qarzi",
        dataIndex: "qarz",
        key: "qarz",
        render: (_, record) => {
          return numberFormat(record.qarz);
        },
      },
    ],
  },
  {
    title: "Masul",
    dataIndex: "eltuvchi",
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
};

export const OrdersDailyTable3 = ({ dailyData }) => {
  // state для модалки ShopEdit
  const [isModalGotov, setIsModalGotov] = useState(false);
  // state для передачи record в ShopEdit
  const [modalGotov, setModalGotov] = useState(null);
  const closeGotovModal = () => {
    setIsModalGotov(false);
  };
  const ReportSingle = (record) => {
    setIsModalGotov(true);
    setModalGotov(record);
  };

  if (!dailyData.order) {
    return <LodingText />;
  }
  if (dailyData.order.length === 0) {
    return <LodingText />;
  }

  let Accounting = Object.values(
    dailyData.order.reduce((r, cur) => {
      const key = "k" + cur["eltuvchi_id"]; // символ "k" добавлен, чтобы автоматически не сортировало по цифровым ключам
      (r[key] = r[key] || []).push(cur);

      return r;
    }, {})
  );
  //   console.log("modalGotov", modalGotov);
  return (
    <>
      <div>
        <Title level={4} style={{ margin: "10px 0" }}>
          Eltuvchilar bo'yicha hisobot
        </Title>
        <Row
          justify="space-around"
          style={{
            margin: "10px 0",
            background: "#001529",
            padding: "10px 0",
            borderRadius: 10,
          }}
        >
          {Accounting.map((i, index) => (
            <Col key={index}>
              <Card
                bordered={true}
                style={{ margin: "10px 0", cursor: "pointer" }}
                onClick={() => ReportSingle(i)}
              >
                <OrderAccounting data={i} />
              </Card>
            </Col>
          ))}
        </Row>
        <Modal
          title={modalGotov?.name}
          open={isModalGotov}
          onCancel={closeGotovModal}
          footer={null}
          width={"90vw"}
        >
          <Title level={4} style={{ margin: "10px 0" }}>
            Buyurtmalar
          </Title>
          <Table
            rowKey={(row) => row.nomer_zakaz}
            columns={columns}
            dataSource={modalGotov}
            onChange={onChange}
            pagination={false}
            style={{ fontSize: 20 }}
          />
        </Modal>
      </div>
    </>
  );
};
