import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { useProduct } from "stor/product/useProduct";
import useCategory from "Provider/Category/useCategory";
import useUsers from "stor/user/useUsers";
import SkladLayout from "Layout/SkladLayout";
import AdminLayout from "Layout/AdminLayout";
import useShops from "stor/shops/useShops";
import "./App.css";

function App() {
  const auth = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const [layout, setLayout] = useState(null);
  const { getUser } = useUsers();
  const { getProduct } = useProduct();
  const { GetCategory } = useCategory();
  const { getShop } = useShops();

  useEffect(() => {
    GetCategory();
    getProduct();
    getUser();
    getShop();
  }, []);

  useEffect(() => {
    if (auth().rol !== "sklad") {
      setLayout(<AdminLayout />);
    } else setLayout(<SkladLayout />);
  }, []);

  return isAuthenticated() ? layout : <Navigate to={"/login"} />;
}

export default App;
