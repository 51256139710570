import React from "react";
import SmsForm from "./SmsForm";
import { Divider, Typography } from "antd";
import { SHOP_BALANS, SHOP_NAME } from "./smsConstants";

const { Text } = Typography;

export default function SmsPage() {
  return (
    <div>
      <SmsForm />
      <Divider />
      <div>
        <p>
          Agar sms xabarga dukon nomini qo`shmoqchi bo`sangiz!!! Manashu
          prefixni yozing <Text mark>{SHOP_NAME}</Text>{" "}
        </p>
        <p>
          Agar sms xabarga dukonni balansini qo`shmoqchi bo`sangiz!!! Manashu
          prefixni yozing <Text mark>{SHOP_BALANS}</Text>{" "}
        </p>
      </div>
    </div>
  );
}
