import React from "react";
import { Table } from "antd";
import Loding from "../Loading/Loding";
import { numberFormat } from "../../util/NumberFormat";

export const OrdersReportHisOrders = ({ orders }) => {
  let totalCash = 0;
  let Karta = 0;
  let Plastik = 0;
  let Naqd = 0;
  orders.forEach(({ karta, naqd, plastik }) => {
    Karta += Number(karta);
    Plastik += Number(plastik);
    Naqd += Number(naqd);
    totalCash = Karta + Plastik + Naqd;
  });
  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      width: 20,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Buyurtma sanasi",
      dataIndex: "buyurtma_sana",
      key: "date",
    },
    {
      title: "Buyurma summasi",
      dataIndex: "jsumma_zakaz",
      key: "summa_zakaz",
      render: (_, record) => {
        return numberFormat(record.summa_zakaz);
      },
    },
    {
      title: "Chegirma",
      dataIndex: "chegirma",
      key: "chegirma",
    },
    {
      title: "Chegirmadan keyingi summa",
      dataIndex: "summa_zakaz_shegirma",
      key: "summa_zakaz_shegirma",
      render: (_, record) => {
        return numberFormat(record.summa_zakaz_shegirma);
      },
    },
    {
      title: "Tulov sana",
      dataIndex: "tulov_sana",
      key: "tulov_sana",
    },
    {
      title: "To'lov turlari",
      children: [
        {
          title: "karta",
          dataIndex: "karta",
          key: "karta",
          render: (_, record) => {
            return numberFormat(record.karta);
          },
        },
        {
          title: "plastik",
          dataIndex: "plastik",
          key: "plastik",
          render: (_, record) => {
            return numberFormat(record.plastik);
          },
        },
        {
          title: "naqd",
          dataIndex: "naqd",
          key: "naqd",
          render: (_, record) => {
            return numberFormat(record.naqd);
          },
        },
        {
          title: "qarz",
          dataIndex: "qarz",
          key: "qarz",
          render: (_, record) => {
            return numberFormat(record.qarz);
          },
        },
      ],
    },
    {
      title: "qoldi",
      dataIndex: "qoldi",
      key: "qarz",
      render: (_, record) => {
        return numberFormat(record.qoldi);
      },
    },
  ];

  if (orders.length === 0) {
    return <Loding />;
  }
  return (
    <div>
      <p>Jami buyurtma to'lov summsi: {numberFormat(totalCash)}</p>
      <Table
        rowKey={(row) => row.id}
        bordered
        dataSource={orders}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{
          x: 1124,
        }}
      />
    </div>
  );
};
