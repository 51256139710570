import { Issuance } from "Context/context";
import {
  ATTENTION,
  ERROR,
  NO,
  OK,
  OOPS,
  SUCCESS,
  SWAL_INFO,
  SWAL_OKTEXT,
  WARNING,
} from "constant/constants";
import useLocalStorage from "hook/useLocalStorage";
import api from "http";
import React, { useEffect, useReducer, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import stockIssuance from "reduser/stockIssuance";
import { useProduct } from "stor/product/useProduct";
import swal from "sweetalert";
import {
  ADD_ISSUANCE_PRODUCT,
  DELETE_ISSUANCE_PRODUCT,
  DELETE_ISSUANCE_PRODUCT_ARR,
} from "types/types";

export default function StockIssuanceState({ children }) {
  const auth = useAuthUser();
  const { product } = useProduct();
  const [localData, setLocalData] = useLocalStorage([], "issuanceFetch");
  const [localDataFetch, setLocalDataFetch] = useLocalStorage([], "issuance");
  const [states, dispatch] = useReducer(stockIssuance, localData);
  const [productId, setProdunctsId] = useState("");
  const [counts, setCounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [deliver, setDeliver] = useState("");

  function onIssuanceProduct() {
    dispatch({
      type: ADD_ISSUANCE_PRODUCT,
      productId,
      counts,
    });
    addListProduct(productId, counts);
    setProdunctsId("");
    setCounts("");
  }
  function addListProduct(value, count) {
    const productList = product.find((item) => item.id === value);
    const oneObject = localDataFetch.find((item) => item.id === value);
    if (oneObject?.id === value) {
      localDataFetch.forEach((item, index) => {
        if (item.id === value) {
          localDataFetch[index].count += count;
        }
      });
      setLocalDataFetch([...localDataFetch]);
    } else {
      setLocalDataFetch([
        ...localDataFetch,
        { id: productList.id, name: productList.name, count },
      ]);
    }
  }

  function onIssuanceDelete(key) {
    dispatch({
      type: DELETE_ISSUANCE_PRODUCT,
      id: key,
    });
    onDeleteList(key);
  }

  function onDeleteList(key) {
    setLocalDataFetch((list) => list.filter((item) => item.id !== key));
  }

  function postIssuance() {
    // console.log({
    //   products: states,
    //   qabul_qildi: deliver,
    //   topshirdi: auth().id,
    // });
    setLoading(true);
    swal({
      title: ATTENTION,
      text: SWAL_INFO,
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post("chiqim-add", {
            products: states,
            qabul_qildi: deliver,
            topshirdi: auth().id,
          })
          .then(function (res) {
            if (res.status !== 200) {
              swal({
                title: OOPS,
                icon: ERROR,
                button: OK,
              });
            } else if (res.status === 200) {
              dispatch({
                type: DELETE_ISSUANCE_PRODUCT_ARR,
              });
              setLocalDataFetch([]);
              swal({
                title: SUCCESS,
                text: res.data.message,
                icon: SUCCESS,
                button: OK,
              });
            }
          })
          .catch(function (error) {
            swal({
              title: OOPS,
              text: error.response.data.message,
              icon: ERROR,
              button: OK,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else setLoading(false);
    });
  }

  useEffect(() => {
    setLocalData(states);
  }, [setLocalData, states]);

  return (
    <Issuance.Provider
      value={{
        setCounts,
        setProdunctsId,
        loading,
        onIssuanceProduct,
        onIssuanceDelete,
        postIssuance,
        counts,
        productId,
        localDataFetch,
        setDeliver,
        deliver,
      }}
    >
      {children}
    </Issuance.Provider>
  );
}
