import { HeaderCollapsed } from "Components/Headers/HeaderCollapsed";
import { HeaderExit } from "Components/Headers/HeaderExit";
import { SidebarMenu } from "Components/menu/Menu";
import Router from "Routes/Router";
import { Layout, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import React, { useState } from "react";

export default function AdminLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgBase },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={!collapsed}>
        {/* <div className="logo" /> */}
        {/* menu */}
        <SidebarMenu />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgBase,
          }}
        >
          <div className="header__content">
            <div>
              <HeaderCollapsed
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            </div>
            <div>
              <HeaderExit />
            </div>
          </div>
        </Header>
        {/* Content */}
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            height: "max-content",
            background: colorBgBase,
          }}
        >
          <Router />
        </Content>
      </Layout>
    </Layout>
  );
}
