import { FaWarehouse } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { BsShopWindow } from "react-icons/bs";
import { GoListOrdered } from "react-icons/go";
import { AiOutlineDownload } from "react-icons/ai";
import { VscRepo } from "react-icons/vsc";
import { TbTruckDelivery, TbReportMoney } from "react-icons/tb";
import { GoSettings } from "react-icons/go";
import { AiOutlineReconciliation } from "react-icons/ai";
import { MailOutlined } from "@ant-design/icons";

export const items = [
  {
    label: "Hisobotlar",
    icon: <TbReportMoney />,
    children: [
      {
        key: "orders-daily",
        label: "Kunlik kassa",
        icon: <TbReportMoney />,
      },

      {
        key: "return",
        label: "Qaytarilgan mahsulotlar",
        icon: <TbReportMoney />,
      },
      {
        key: "actsverka",
        label: "Akt-sverka",
        icon: <AiOutlineReconciliation />,
      },
      {
        key: "debit-kredit",
        label: "Do'konlar his.",
        icon: <TbReportMoney />,
      },
      {
        key: "debtorsList",
        label: "Qarzdorlar ro'yhati",
        icon: <TbReportMoney />,
      },
    ],
  },
  {
    key: "orders",
    label: "Yangi buyurtmalar",
    icon: <GoListOrdered />,
  },
  {
    key: "ordersConfirm",
    label: "Qabul qilingan buyurtmalar",
    icon: <AiOutlineDownload />,
  },
  {
    key: "ordersFilter",
    label: "Buyurtma bo'lgan maxsulotlar",
    icon: <VscRepo />,
  },
  {
    key: "deliver",
    label: "Eltuvchi biriktirilgan maxsulotlar",
    icon: <TbTruckDelivery style={{ fontSize: 18 }} />,
  },
  {
    key: "personal",
    label: "Xodimlar",
    icon: <FiUsers />,
  },
  {
    label: "Sklad",
    icon: <FaWarehouse />,
    children: [
      {
        key: "finished-goods-warehouse",
        label: "Tayyor maxsulot qo`shish",
      },
      {
        key: "stock-issuance",
        label: "Skladdan chiqim",
      },
      {
        key: "product",
        label: "Barcha Maxsulotlar",
      },
    ],
  },
  {
    key: "shop",
    label: "Do'konlar",
    icon: <BsShopWindow />,
  },
  {
    key: "category",
    label: "Sozlamalar",
    icon: <GoSettings />,
  },
  {
    key: "sms",
    label: "Sms",
    icon: <MailOutlined />,
  },
  {
    key: "dan_order",
    label: "Bajarilgan buyurtmalar",
    icon: <GoListOrdered />,
  },
  {
    key: "all_orders",
    label: "Barcha buyurtmalar",
    icon: <GoListOrdered />,
  },
];
