import { FileExcelOutlined } from '@ant-design/icons';
import { OrdersItem } from 'Components/OrdersConfirmed/OrdersItem';
import { OrdersTransfer } from 'Components/OrdersConfirmed/OrdersTransfer/OrdersTransfer';
import { Button, Col, Divider, FloatButton, Modal, Row, Select, Space, Table, Tag } from 'antd';
import api from 'http';
import React, { useEffect, useState } from 'react';
import { numberFormat } from "util/NumberFormat";
import dateStringFormat from "util/dateStringFormat";
import * as XLSX from 'xlsx';

function AllOrders(props) {

    let [orders, setOrders] = useState([])
    let [shops, setShops] = useState([])
    let [loading, setLoading] = useState(false)
    let [excloading, setExcloading] = useState(false)
    let [modal, setModal] = useState(false)
    let [items, setItems] = useState([])

    useEffect(() => {
        api.get('/shops-get').then(res => {
            console.log(res.data);
            setShops(res.data)
        })
            .catch(err => {
                console.log(err);
            })
        // api.get('/allorders').then(res => {
        //     console.log(res.data);
        //     setOrders(res.data)
        // })
        //     .catch(err => {
        //         console.log(err);
        //     })
    }, [])
    const columns = [
        {
            title: "Sana",
            dataIndex: "sana",
            key: "date",
            render: (_, rec) => {
                return dateStringFormat(rec.sana);
            },
        },
        {
            title: "Summa",
            dataIndex: "summa",
            key: "summa",
            render: (_, record) => {
                return numberFormat(record.summa);
            },
        },
        {
            title: "%",
            dataIndex: "chegirma",
            key: "chegirma",
            render: (_, ren) => {
                return <Tag color="geekblue">{ren.chegirma}%</Tag>;
            },
        },
        {
            title: "Sof summa",
            dataIndex: "total_skidka",
            key: "total_skidka",
            render: (_, record) => {
                return numberFormat(record.total_skidka);
            },
        },
        {
            title: "To'lov turlari",
            children: [
                {
                    title: "Naqd",
                    dataIndex: "naqd",
                    key: "naqd",
                    render: (_, record) => {
                        return numberFormat(record.naqd);
                    },
                },
                {
                    title: "Bank",
                    dataIndex: "plastik",
                    key: "plastik",
                    render: (_, record) => {
                        return numberFormat(record.plastik);
                    },
                },
                {
                    title: "Karta",
                    dataIndex: "karta",
                    key: "karta",
                    render: (_, record) => {
                        return numberFormat(record.karta);
                    },
                },
                {
                    title: "Qolgan qarzi",
                    dataIndex: "qarz",
                    key: "qarz",
                    render: (_, record) => {
                        return numberFormat(record.qarz);
                    },
                },
            ],
        },
        {
            title: "Xolati",
            dataIndex: "status",
            key: "status",
            render: (_, record) => {
                if (record.status === "done") {
                    return <Tag color="#87d068">Bajarilgan</Tag>;
                } else {
                    return <Tag color="red">!</Tag>;
                }
            },
        },
        {
            title: "Amallar",
            key: "operation",
            render: (_, record) => (
                <Space direction="horizontal">
                    <Button type='primary' onClick={() => {
                        setModal(true)
                        api.get('/orditems/' + record.id).then(res => {
                            console.log(res.data);
                            setItems(res.data)
                        }).catch(err => {
                            console.log(err);
                        })
                    }}>Batafsil</Button>
                </Space>
            ),
        },
    ];
    let column = columns.map((item) => {
        return Object.assign(
            {
                sorter: (a, b) => {
                    // a.dataIndex.length - b.dataIndex.length
                    if (a[item.dataIndex] > b[item.dataIndex]) return 1;
                    if (a[item.dataIndex] === b[item.dataIndex]) return 0;
                    if (a[item.dataIndex] < b[item.dataIndex]) return -1;
                },
                sortDirections: ["descend", "ascend"],
            },
            item
        );
    });
    let handeleChange = (value) => {
        setLoading(true)
        api.get(`/allorders/${value}`).then(res => {
            console.log(res.data);
            setLoading(false)
            setOrders(res.data)
        })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false))
    }

    let excelDownload = () => {
        setExcloading(true)
        api.get('/allordersa').then(res => {
            console.log(res.data);
            // 1. Ma'lumotlarni tayyorlash
            const worksheet = XLSX.utils.json_to_sheet(res.data.orders); // JSONni jadvalga aylantirish
            const workorders = XLSX.utils.json_to_sheet(res.data.dukonlar); // JSONni jadvalga aylantirish
            const workbook = XLSX.utils.book_new(); // Yangi Excel fayli yaratish
            XLSX.utils.book_append_sheet(workbook, worksheet, "Buyurtmalar"); // "Users" nomli sahifa qo'shish
            XLSX.utils.book_append_sheet(workbook, workorders, "Do'konlar"); // "Users" nomli sahifa qo'shish

            // 2. Faylni saqlash
            XLSX.writeFile(workbook, "Buyurtmalar.xlsx"); // Fayl nomi
            setExcloading(false)
        })
            .catch(err => {
                console.log(err);
            })


    }

    let mcols = [
        {
            title: "Maxsulot",
            dataIndex: "product",
            key: "product",
        },
        {
            title: "Soni",
            dataIndex: "soni",
            key: "soni",
        },
        {
            title: "Narxi",
            dataIndex: "price",
            key: "price",
        },
        {
            title: "Summa",
            dataIndex: "summa",
            key: "summa",
        },
    ]

    return (
        <div>
            <Modal title={"Buyurtma elementlari"} footer={false} open={modal} onCancel={() => {
                setItems([])
                setModal(false)
            }}>
                <Table columns={mcols} rowKey={'id'} dataSource={items} />
            </Modal>
            <Row gutter={24}>
                <Col span={16}>
                </Col>
                <Col span={8}>
                    <Button loading={excloading} onClick={excelDownload} icon={<FileExcelOutlined />} block type='primary' style={{ backgroundColor: 'green' }}>EXCEL FORMATIDA BARCHA BUYURTMALAR va DO'KONLAR RO'YXATINI YUKLASH</Button>
                </Col>
            </Row>
            <Divider />
            <Select
                showSearch
                style={{ width: "100%" }}
                size='large'
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={shops.map(shop => ({ value: shop.id, label: shop.name }))}
                onChange={handeleChange}
            />
            <Divider />
            <Table
                rowKey={'id'}
                columns={column}
                pagination={{
                    position: ["topLeft"], //  pageSize: "8",
                }}
                scroll={{
                    x: 1124,
                }}
                dataSource={orders}
                loading={loading}

            />
            <FloatButton
                icon={<FileExcelOutlined />}
                description="EXCEL"
                shape="square"
                type="primary"
                style={{ backgroundColor: 'green' }}
            />
        </div>
    );
}

export default AllOrders;


// https://ustomer.public-customer-pull.motorcycles/lld.succes.chec