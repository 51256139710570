import React, { useRef, useContext, useState, useEffect } from "react";
import { Button, Form, Input, Select, Row, Col } from "antd";
import ModalCenter from "../ModalComponent/ModalComponent/ModalCenter/ModalCenter";
import swal from "sweetalert";
import { NumericFormat } from "react-number-format";
import { TabsProvider } from "../../Provider/StoreProvider";
import { PatternFormat } from "react-number-format";
import api from "http";
import { deleteSimbolFromNumber } from "util/deleteSimbolFromNumber";

export const ShopNewAdd = ({ zion, cities }) => {
  const GetTotalShop = useContext(TabsProvider);

  const [form] = Form.useForm();
  let RefBalans = useRef(null);
  const [userData, setUserData] = useState([]);

  // Запрос для таблицы рабочих
  const FetchUserData = () => {
    api
      .post("get-users")
      .then(function (res) {
        if (res.status === 200) {
          setUserData(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchUserData();
    // eslint-disable-next-line
  }, []);

  // выводим имена агентов
  const Agent = userData.map((i) =>
    i.rol === "agent" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  // выводим имена доставщиков
  const Dostavshik = userData.map((i) =>
    i.rol === "dostavka" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );

  // добавляем нового клиента
  const onFinish = (values) => {
    values.balans = deleteSimbolFromNumber(RefBalans.current.input.value);
    swal({
      title: "Diqqat!",
      text: `Ma'lumotlarni to'g'ri kirgizganingizga ishonchingiz komilmi`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post("shops-add", values)
          .then(function (res) {
            console.log(res.data);
            if (res.data.error === 1) {
              swal({
                title: "Oops.....",
                icon: "error",
                button: "Ок",
              });
            } else if (res.status === 200) {
              swal({
                title: "Yaxshi",
                text: `${res.data.xabar}`,
                icon: "success",
                button: "Ок",
              });
              GetTotalShop();
              form.resetFields();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  const dataCities = cities.map((i) => (
    <Select.Option key={i.id} value={i.id}>
      {i.nomi}
    </Select.Option>
  ));
  // получаем значения из select viloyatlar
  const [filterTuman, setFilterTuman] = useState();
  const ValueViloyat = (val) => {
    setFilterTuman(val);
    //  console.log(val);
  };

  //передаём значение в select tuman
  const optionTuman = zion.map((i) =>
    filterTuman === i.vil_id ? (
      <Select.Option key={i.id} value={i.id}>
        {i.nomi}
      </Select.Option>
    ) : null
  );
  return (
    <ModalCenter name="Yangi do'kon qo'shish">
      <h1>Yangi do'kon qo'shish</h1>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Do'kon nomi"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Do'kon nomi kiriting!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            {" "}
            <Form.Item label="Egasi yoki manadjer" name="fio">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            {" "}
            <Form.Item label="Viloyat" name={"viloyat_id"}>
              <Select onChange={ValueViloyat}>
                {dataCities !== 0 ? dataCities : null}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Tuman" name={"tuman_id"}>
              <Select>{optionTuman}</Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Manzil" name="manzil">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            {" "}
            <Form.Item label="Telefon" name="telefon">
              <PatternFormat
                format="+998 (##) ### ## ##"
                allowEmptyFormatting
                mask="_"
                customInput={Input}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Balansi">
              <NumericFormat
                allowLeadingZeros
                thousandSeparator={true}
                customInput={Input}
                getInputRef={RefBalans}
              />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            {" "}
            <Form.Item label="Qarzi">
              <NumericFormat
                allowLeadingZeros
                thousandSeparator={true}
                customInput={Input}
                getInputRef={RefQarz}
              />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Biriktirilgan agent" name="agent_id">
              <Select>{Agent}</Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Biriktirilgan eltuvchi" name="eltuvchi_id">
              <Select>{Dostavshik}</Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Kiritish
          </Button>
        </Form.Item>
      </Form>
    </ModalCenter>
  );
};
