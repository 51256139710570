export const SWAL_INFO = `Ma'lumotlarni to'g'ri kirgizganingizga ishonchingiz komilmi`;
export const ARE_YOU_SURE = "Ishonchingiz komilmi???";
export const SUCCESS_TEXT = "Ma'lumotlar taxrirlandi";
export const SUCCESS_SMS = "Sms Xabar yuborildi";
export const SWAL_OKTEXT = "Ha";
export const ATTENTION = "Diqqat!";
export const NO = "Yo'q!";
export const OK = "Ok";
export const OOPS = "Oops.....";
export const NOSAVE = `Saqlanmadi`;
export const SUCCESSOK = "Yaxshi";
export const SUCCESS = "success";
export const WARNING = "warning";
export const ERROR = "error";
