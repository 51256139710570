import React from "react";
import StockList from "Components/custom/List";
import StockAddProduct from "Components/custom/StockAddProduct";
import { Divider, Row, Typography } from "antd";
import { useProduct } from "stor/product/useProduct";
import StockIssuanceForm from "./StockIssuanceForm";
import useIssuance from "stor/stock/useIssuance";
import BalanceGoods from "Components/BalanceGoodsDeliver/BalanceGoods";

export default function StockIssuance() {
  const {
    setCounts,
    setProdunctsId,
    loading,
    onIssuanceProduct,
    onIssuanceDelete,
    postIssuance,
    counts,
    productId,
    localDataFetch,
    setDeliver,
    deliver,
  } = useIssuance();
  const { product } = useProduct();
  return (
    <div>
      <Row justify="space-between">
        <Typography.Title level={5}>
          Eltuvchiga maxsulot berish
        </Typography.Title>
        <BalanceGoods />
      </Row>
      <Divider />
      <StockAddProduct
        setProduncts={setProdunctsId}
        value={productId}
        data={product}
        count={counts}
        setCount={setCounts}
        onProduct={onIssuanceProduct}
      />
      <Divider />
      <StockList
        footerComponent={
          <StockIssuanceForm
            postProduct={postIssuance}
            setDeliver={setDeliver}
            delivers={deliver}
          />
        }
        deletes={true}
        loading={loading}
        dataList={localDataFetch}
        onDelete={onIssuanceDelete}
      />
    </div>
  );
}
