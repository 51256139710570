import React from "react";
import { Space, Table, Tag } from "antd";
import { OrdersTransfer } from "./OrdersTransfer/OrdersTransfer";
import LodingText from "../Loading/LodingText";
import { FloatButton } from "antd";
import dateStringFormat from "../../util/dateStringFormat";
import { OrdersItem } from "./OrdersItem";
import { numberFormat } from "util/NumberFormat";

export const OrderConfiredList = ({ filtered }) => {
  const columns = [
    {
      title: "Sana",
      dataIndex: "sana",
      key: "date",
      render: (_, rec) => {
        return dateStringFormat(rec.sana);
      },
    },
    {
      title: "Do'kon nomi",
      dataIndex: "d_name",
      key: "dukon_name",
    },
    {
      title: "Summa",
      dataIndex: "summa",
      key: "summa",
      render: (_, record) => {
        return numberFormat(record.summa);
      },
    },
    {
      title: "%",
      dataIndex: "chegirma",
      key: "chegirma",
      render: (_, ren) => {
        return <Tag color="geekblue">{ren.chegirma}%</Tag>;
      },
    },
    {
      title: "Sof summa",
      dataIndex: "total_skidka",
      key: "total_skidka",
      render: (_, record) => {
        return numberFormat(record.total_skidka);
      },
    },
    {
      title: "To'lov turlari",
      children: [
        {
          title: "Naqd",
          dataIndex: "naqd",
          key: "naqd",
          render: (_, record) => {
            return numberFormat(record.naqd);
          },
        },
        {
          title: "Bank",
          dataIndex: "plastik",
          key: "plastik",
          render: (_, record) => {
            return numberFormat(record.plastik);
          },
        },
        {
          title: "Karta",
          dataIndex: "karta",
          key: "karta",
          render: (_, record) => {
            return numberFormat(record.karta);
          },
        },
        {
          title: "Qolgan qarzi",
          dataIndex: "qarz",
          key: "qarz",
          render: (_, record) => {
            return numberFormat(record.qarz);
          },
        },
      ],
    },
    {
      title: "Mas'ul agent",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Eltuvchi",
      dataIndex: "eltuvchi_name",
      key: "eltuvchi_name",
    },
    {
      title: "Xolati",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status === "new") {
          return <Tag color="#f50">Yangi buyurtma</Tag>;
        } else if (record.status === "confirmed") {
          return <Tag color="#87d068">Qabul qilingan</Tag>;
        } else {
          return <Tag color="#008080">Berilgan</Tag>;
        }
      },
    },
    {
      title: "Amallar",
      key: "operation",
      render: (_, record) => (
        <Space direction="horizontal">
          {/*<Link to={`${record.id}`} state={{data: record}}>Batafsil</Link>*/}
          <OrdersItem record={record} />
          <OrdersTransfer ordersData={record} />
          {/* <OrdersTransferUpdate ordersData={record} /> */}
        </Space>
      ),
    },
  ];
  let column = columns.map((item) => {
    return Object.assign(
      {
        sorter: (a, b) => {
          // a.dataIndex.length - b.dataIndex.length
          if (a[item.dataIndex] > b[item.dataIndex]) return 1;
          if (a[item.dataIndex] === b[item.dataIndex]) return 0;
          if (a[item.dataIndex] < b[item.dataIndex]) return -1;
        },
        sortDirections: ["descend", "ascend"],
      },
      item
    );
  });

  if (filtered.length === 0) {
    return <LodingText />;
  }

  return (
    <div style={{ height: "max-content" }}>
      <Table
        rowKey={(row) => row.id}
        columns={column}
        pagination={{
          position: ["topLeft"], //  pageSize: "8",
        }}
        scroll={{
          x: 1124,
        }}
        dataSource={filtered}
      />
      <FloatButton.BackTop type="primary" />
    </div>
  );
};
