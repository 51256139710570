import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useStock } from "stor/stock/useStock";

const { Item } = Form;

export default function SkladDailyArrivalForm() {
  const auth = useAuthUser();
  const { stockDaySort } = useStock();
  return (
    <Form
      layout="vertical"
      onFinish={stockDaySort}
      initialValues={{
        user_id: auth().id,
      }}
    >
      <Item name="user_id" hidden>
        <Input />
      </Item>
      <Row wrap={true} gutter={8}>
        <Col flex={4}>
          <Item
            name="sana1"
            rules={[
              {
                required: true,
                message: "Sanani tanlang",
              },
            ]}
          >
            <DatePicker
              placement={"bottomRight"}
              placeholder="Boshlanish sanasi"
              style={{ width: "100%" }}
            />
          </Item>
        </Col>
        <Col flex={4}>
          <Item
            name="sana2"
            rules={[
              {
                required: true,
                message: "Sanani tanlang",
              },
            ]}
          >
            <DatePicker
              placement={"bottomRight"}
              style={{ marginLeft: 5, width: "100%" }}
              placeholder="Tugash sanasi"
            />
          </Item>
        </Col>
        <Col flex={4}>
          <Item>
            <Button htmlType="submit" type="primary">
              Saralash
            </Button>
          </Item>
        </Col>
      </Row>
    </Form>
  );
}
