import React, { useState, useEffect } from "react";
import ActSwerkiTable from "./ActSwerkiTable";
import Print from "../../../util/Print";
import api from "http";

function ActSwerkiWrapper(props) {
  const [shop, setShop] = useState([]);

  const GetTotalShop = () => {
    api
      .post("shops-get")
      .then(function (res) {
        if (res.status === 200) {
          setShop(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetTotalShop();
  }, []);

  return (
    <Print title="Solishtirish dalolatnomasi">
      <ActSwerkiTable shop={shop} />
    </Print>
  );
}

export default ActSwerkiWrapper;
