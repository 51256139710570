import React from "react";
import { Input } from "antd";
export const OrdersConfirmedSearch = (props) => {
  return (
    <Input
      onChange={({ target: { value } }) => props.Search(value)}
      type="text"
      placeholder="Qidirish..."
      style={{ width: "300px", fontSize: "16px" }}
    />
  );
};
