import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "Components/SkladLayot/Header";
import StockIssuance from "Components/stock/StockIssuance";
import StockProduct from "Components/stock/StockProduct";
import "../Components/SkladLayot/skladLayout.css";
import SkladWrapper from "Components/SkladLayot/SkladWrapper";
export default function SkladLayout() {
  return (
    <main className="sklad__wrapper">
      <Header />
      <main className="sklad__content">
        <Routes>
          <Route path="/" element={<SkladWrapper />} />
          <Route path="/finished-goods-warehouse" element={<StockProduct />} />
          <Route path="/stock-issuance" element={<StockIssuance />} />
        </Routes>
      </main>
    </main>
  );
}
