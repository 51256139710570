import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, InputNumber } from "antd";
import swal from "sweetalert";
import { PatternFormat } from "react-number-format";
import { TabsProvider } from "../../Provider/StoreProvider";
import api from "http";

const { Option } = Select;

const ShopEdit = ({ shopData, cities, zion, form }) => {
  const GetTotalShop = useContext(TabsProvider);
  const [userData, setUserData] = useState([]);

  // Запрос для таблицы рабочих
  const FetchUserData = () => {
    api
      .post("get-users")
      .then(function (res) {
        if (res.status === 200) {
          setUserData(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchUserData();
    // eslint-disable-next-line
  }, []);

  // выводим имена агентов
  const Agent = userData.map((i) =>
    i.rol === "agent" && shopData.agent_id !== i.id ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  // выводим имена доставщиков
  const Dostavshik = userData.map((i) =>
    i.rol === "dostavka" && shopData.eltuvchi_id !== i.id ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );

  // выводим название viloyatlar
  // eslint-disable-next-line
  const optionsViloyat = cities.map((i) => {
    if (shopData.viloyat_id !== i.id) {
      return (
        <Select.Option key={i.id} value={i.id}>
          {i.nomi}
        </Select.Option>
      );
    }
  });
  // получаем значения из select viloyatlar
  const [filterTuman, setFilterTuman] = useState();
  const ValueViloyat = (val) => {
    setFilterTuman(val);
  };
  //передаём значение в select tuman
  const optionTuman = zion.map((i) =>
    filterTuman === i.vil_id && shopData.tuman_id !== i.id ? (
      <Select.Option key={i.id} value={i.id}>
        {i.nomi}
      </Select.Option>
    ) : null
  );

  // добавляем нового клиента
  const onFinish = (values) => {
    values.id = shopData.id;
    swal({
      title: "Diqqat!",
      text: `Ma'lumotlarni to'g'ri kirgizganingizga ishonchingiz komilmi`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post(`shop/${shopData.id}`, values)
          .then(function (res) {
            GetTotalShop();
            // console.log(res.data);
            if (res.data.error === 1) {
              swal({
                title: "Oops.....",
                text: `${res.status}`,
                icon: "error",
                button: "Ок",
              });
              GetTotalShop();
            } else if (res.data.error === 0) {
              swal({
                title: "Yaxshi",
                icon: "success",
                button: "Ок",
              });
              form.resetFields();
              GetTotalShop();
            }
          })
          .catch(function (error) {
            console.log(error);
            GetTotalShop();
          });
      }
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row gutter={8}>
        <Col span={8}>
          <Form.Item
            label="Do'kon nomi"
            name="name"
            //   rules={[
            //     {
            //       required: true,
            //       message: "Do'kon nomi kiriting!",
            //     },
            //   ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Egasi yoki manadjer" name="fio">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Balansi" name="balans">
            <InputNumber
              style={{ width: "100%" }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Viloyat" name="viloyat_id">
            <Select onChange={ValueViloyat}>
              <Option value={shopData.viloyat_id}>
                {shopData.viloyat_name}
              </Option>
              {optionsViloyat}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Tuman" name="tuman_id">
            <Select>
              <Option selec value={shopData.tuman_id}>
                {shopData.tuman_name}
              </Option>
              {optionTuman}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Manzil" name="manzil">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Telefon" name="telefon">
            <PatternFormat
              format="+998 (##) ### ## ##"
              allowEmptyFormatting
              mask="_"
              customInput={Input}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Biriktirilgan agent" name="agent_id">
            <Select>
              {/* <Option value={shopData.agent_id}>
                {shopData.agent_name}
              </Option> */}
              {Agent}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label="Biriktirilgan eltuvchi" name="eltuvchi_id">
            <Select>
              <Option value={shopData.eltuvchi_id}>
                {shopData.eltuvchi_name}
              </Option>
              {Dostavshik}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Form.Item>
          <Button type="primary" danger htmlType="submit">
            Saqlash
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default ShopEdit;
