import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip, Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { useSignOut } from "react-auth-kit";

export const HeaderExit = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const Remove = () => {
    localStorage.removeItem("DataToken");
    signOut();
    navigate("/login");
  };
  //   const BtnStyle = {
  //     background: "#ff4d4f",
  //     color: "#fff",
  //     borderRadius: "5px",
  //     borderColor: "#ff4d4f",
  //     padding: "0 10px",
  //     margin: "0 2px 0 0",
  //     fontSize: "14px",
  //     height: "inherit",
  //     cursor: "pointer",
  //   };
  return (
    <div style={{ paddingRight: 15 }}>
      <Tooltip placement="leftTop" title={"Chiqish"} color={"geekblue"}>
        <Button type="primary" danger onClick={Remove}>
          <ExportOutlined style={{ color: "#fff" }} />
        </Button>
      </Tooltip>
    </div>
  );
};
