import React, { useState } from "react";
import { BalansGoods } from "Context/context";
import { useAuthUser } from "react-auth-kit";
import api from "http";
import swal from "sweetalert";
import {
  ATTENTION,
  ERROR,
  NO,
  OK,
  OOPS,
  SUCCESS,
  //   SWAL_INFO,
  SWAL_OKTEXT,
  WARNING,
} from "constant/constants";

export default function DeliveryBalansGoodsState({ children }) {
  const auth = useAuthUser();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserID] = useState(0);

  function onFilter(value) {
    setUserID(value?.eltuvchi_id);
    setLoading(true);
    api
      .post("get-deliver-product", value)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onReturnStockProduct() {
    swal({
      title: ATTENTION,
      text: "ishonchingiz komilmi",
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post("krim-eltuvchi-add", {
            eltuvchi_id: userId,
            skladchi_id: auth().id,
            products: data,
          })
          .then(function (res) {
            if (res.status === 200) {
              swal({
                title: SUCCESS,
                text: res.data.message,
                icon: SUCCESS,
                button: OK,
              });
              onFilter({ eltuvchi_id: userId });
            }
          })
          .catch(function (error) {
            swal({
              title: OOPS,
              text: error.message,
              icon: ERROR,
              button: OK,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else setLoading(false);
    });
  }

  return (
    <BalansGoods.Provider
      value={{ onFilter, data, loading, onReturnStockProduct, userId }}
    >
      {children}
    </BalansGoods.Provider>
  );
}
