import React from "react";
import SkladDailyArrivalForm from "./SkladDailyArrivalForm";
import { useStock } from "stor/stock/useStock";
import { Table } from "antd";
import { nanoid } from "nanoid";
import Print from "util/Print";

export default function SkladWrapper() {
  const { dailyData, dayDataLoading } = useStock();
  const columns = [
    {
      title: "№",
      dataIndex: "pr_id",
      width: 20,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Nomi",
      dataIndex: "nomi",
    },
    {
      title: "Soni",
      dataIndex: "soni",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.soni - b.soni,
    },
  ];
  return (
    <>
      <SkladDailyArrivalForm />
      <Print title={"Maxsulotlar"}>
        <Table
          rowKey={() => nanoid()}
          columns={columns}
          dataSource={dailyData}
          pagination={false}
          style={{ fontSize: 20 }}
          loading={dayDataLoading}
        />
      </Print>
    </>
  );
}
