import { Button, Checkbox, Col, Divider, Input, Row, Select } from "antd";
import useShops from "stor/shops/useShops";
import useSms from "stor/sms/useSms";

const { TextArea } = Input;

export default function SmsForm() {
  const { shop } = useShops();
  const {
    onAllShopCheck,
    setValue,
    onSendSms,
    onSelect,
    value,
    isDisabled,
    buttonDisabled,
    onAllDebtors,
  } = useSms();
  return (
    <>
      <Row gutter={12}>
        <Col span={8}>
          {" "}
          <Select
            mode="multiple"
            allowClear
            onChange={onSelect}
            showSearch
            style={{
              width: "100%",
            }}
            placeholder="Dukonlar nomi..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={shop.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </Col>
        <Col span={8}>
          <Checkbox onChange={onAllShopCheck}>
            Barcha do`konlarga xabar yuborish
          </Checkbox>
        </Col>
        <Col span={8}>
          <Checkbox onChange={onAllDebtors}>
            Barcha qarzdorlarga xabar yuborish
          </Checkbox>
        </Col>
      </Row>
      <Divider />
      <Row>
        <TextArea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          rows={4}
          disabled={isDisabled}
        />
      </Row>
      <Row justify="end">
        <Button
          style={{ marginTop: 15 }}
          type="primary"
          onClick={() => onSendSms()}
          disabled={buttonDisabled}
        >
          Yuborish
        </Button>
      </Row>
      <h2>Yuborish mumkin bo'lgan matn namunalari</h2>
      <ul style={{ lineHeight: '50px', fontSize: '16px' }}>
        <li>Assalomu alaykum Sizning to'lovingiz "Agent yoki Yetkazuvchi FISH" tomonidan qabul qilindi! Balansingizda {'{'}dukon_balans{'}'} so'm qarz mavjud. (Visola Aziz Platformasidan habar)</li>
        <li>Assalomu alaykum  {'{'}dukon_nomi{'}'} jamoasi! Visola Aziz korxonasining barcha xodimlari nomidan Sizni va oila a'zolaringizni Mustaqillik bayrami bilan chin qalbdan tabriklaymiz!!! (Visola Aziz Platformasidan habar)</li>
        <li>Assalomu alaykum {'{'}dukon_nomi{'}'} hodimi. Visola Aziz tizimidagi Balansingizda {'{'}dukon_balans{'}'} so'm qarz mavjud. (Visola Aziz Platformasidan habar)</li>
      </ul>
    </>
  );
}
