import React from "react";
import Loding from "../../Loading/Loding";
import useCategory from "../../../Provider/Category/useCategory";
import CategoryTable from "./CategoryTable";
import CategoryAddModal from "./CategoryAddModal";
import { Row, Typography } from "antd";

const { Title } = Typography;

export default function Category() {
  const { category, loading, GetCategory } = useCategory();
  if (!loading) {
    return <Loding />;
  }
  return (
    <div>
      <Row justify={"space-between"}>
        <Title level={4}>Mahsulot toifalari</Title>
        <CategoryAddModal GetCategory={GetCategory} />
      </Row>
      <CategoryTable category={category} GetCategory={GetCategory} />
    </div>
  );
}
