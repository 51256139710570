import ModalCenter from "Components/ModalComponent/ModalComponent/ModalCenter/ModalCenter";
import React from "react";
import BalanceGoodsForm from "./BalanceGoodsForm";
import BalanceGoodsList from "./BalanceGoodsList";
import { Button, Row } from "antd";
import useDeliveryBalansGoods from "stor/stock/useDeliveryBalansGoods";

export default function BalanceGoods() {
  const { onReturnStockProduct, loading, userId } = useDeliveryBalansGoods();
  return (
    <ModalCenter name="Eltuvchida qolgan maxsulot">
      <BalanceGoodsForm />
      <BalanceGoodsList />
      <Row justify="end">
        <Button
          disabled={userId !== null ? false : true}
          loading={loading}
          style={{ margin: "15px 0" }}
          type="primary"
          onClick={onReturnStockProduct}
        >
          Maxsulotlarni qabul qilish
        </Button>
      </Row>
    </ModalCenter>
  );
}
