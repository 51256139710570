import React, { useState } from "react";
import { Button, Col, Form, Row, Table } from "antd";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import CategoryUpdateModal from "./CategoryUpdateModal";
import useCategory from "Provider/Category/useCategory";

const CategoryTable = ({ category, GetCategory }) => {
  const { deleyeCategory } = useCategory();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isvalue, setValue] = useState(false);
  const showModal = (e) => {
    setOpen(true);
    setValue(e);
    form.setFieldsValue({
      name: e.name,
      id: e.id,
    });
  };
  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    { title: "Toifalar nomi", dataIndex: "name", key: "name" },
    {
      title: "Amallar",
      width: 150,
      render: (_, record) => {
        return (
          <div>
            <Row>
              <Col span={12}>
                <Button type="primary" onClick={() => showModal(record)}>
                  <BiEditAlt />
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  danger
                  onClick={() => deleyeCategory(record.id)}
                >
                  {" "}
                  <RiDeleteBin5Line />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        rowKey={(row) => row.id}
        dataSource={category}
        columns={columns}
        pagination={false}
      />
      <CategoryUpdateModal
        open={open}
        setOpen={setOpen}
        isvalue={isvalue}
        form={form}
        GetCategory={GetCategory}
      />
    </div>
  );
};

export default CategoryTable;
