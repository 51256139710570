import { DELETE_PRODUCT, ADD_PRODUCT, DELETE_ARR } from "types/types";

/* eslint-disable default-case */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state, action) {
  switch (action?.type) {
    case ADD_PRODUCT:
      const items = state.filter(
        (item) => item.product_id === action.product_id
      );
      if (items[0]) {
        state.forEach((element, index) => {
          if (element.product_id === action.product_id) {
            state[index].count += action.count;
          }
        });
        return [...state];
      } else {
        return [
          ...state,
          {
            product_id: action.product_id,
            count: action.count,
          },
        ];
      }
    case DELETE_PRODUCT:
      return state.filter((item) => item.id !== action.product_id);
    case DELETE_ARR:
      state = [];
      return state;
  }
}
