import { Form, Input, InputNumber, Select, message, Modal, Image } from "antd";
import Upload from "antd/es/upload/Upload";
import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import swal from "sweetalert";
import ImgCrop from "antd-img-crop";
import { configs } from "config";
import api from "http";

export default function UpdateForm({ handleFinesh, form, category }) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // действия с upload
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const uploadButton = (
    <div style={{ width: "100%" }}>
      <CloudUploadOutlined />
      <p className="ant-upload-text">Format JPG, PNG, JPEG</p>
      <p className="ant-upload-hint">Faylni tanlash</p>
    </div>
  );

  return (
    <>
      <Form onFinish={handleFinesh} form={form} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="Nomi" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Hajmi" name="soni">
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Narxi" name="price">
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="O'lchov birligini tanlang" name={"birlik"}>
          <Select>
            <Select.Option value="gramm">Gramm</Select.Option>
            <Select.Option value="dona">Dona</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Toifani tanlang" name={"category_id"}>
          <Select>
            {category.map((i) => (
              <Select.Option key={i.id} value={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Rasm"
          name="img"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <ImgCrop
            showGrid
            rotationSlider
            aspectSlider
            modalCancel="Yopish"
            modalOk="Qo'shish"
            modalTitle="Кфыь sozlamalarш"
            onModalOk={(file) => {
              const formData = new FormData();
              formData.append("images", file);
              api
                .post(configs.uploadImg, formData)
                .then((json) => {
                  if (json.data.status === "success") {
                    message.success("Rasm yuklandi");
                    form.setFieldsValue({
                      img: json.data.data.image,
                      duration: 3,
                    });
                  } else
                    swal({
                      title: json.status,
                      text: json.xabar,
                      icon: "error",
                      button: "Ок",
                    });
                })
                .catch((err) => {
                  swal({
                    title: "ERROR",
                    text: `${err}`,
                    icon: "error",
                    button: "Ок",
                  });
                });
            }}
          >
            <Upload
              style={{ width: "100vw", zIndex: 99999 }}
              action=""
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              progress={true}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Image src={previewImage} alt="image" />
      </Modal>
    </>
  );
}
