import React, { useState, useRef } from "react";
import { OrdersFilterForm } from "./OrdersFilterForm";
import { OrdersDataTable } from "./OrdersDataTable";
import ReactToPrint from "react-to-print";
import { Button, Typography } from "antd";
import api from "http";

const { Title } = Typography;

export const OrdersFilter = () => {
  // получаем данные одного агента
  const [agent, setAgent] = useState([]);
  // получаем данные формы и передаем таблице
  const [interval, setInterval] = useState([]);
  // loding
  const [isLoding, setIsLoding] = useState(false);
  // даты
  const [dateOne, setDateOne] = useState("");
  const [date2, setDate2] = useState("");
  // ref для печати
  const componentRef = useRef();

  const FetchUserSingle = (id) => {
    api
      .post("get-user-single", { id: id })
      .then(function (res) {
        if (res.status === 200) {
          setAgent([res.data]);
          //  console.log("agent", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Title level={4}>Kunlik buyurtmalar ro'yhati</Title>
      <div style={Style}>
        <OrdersFilterForm
          setInterval={setInterval}
          setIsLoding={setIsLoding}
          isLoding={isLoding}
          {...{ FetchUserSingle }}
          setDateOne={setDateOne}
          setDate2={setDate2}
        />
        <ReactToPrint
          trigger={() => <Button type="primary">печатать</Button>}
          content={() => componentRef.current}
        />
      </div>
      <OrdersDataTable
        interval={interval}
        componentRef={componentRef}
        agent={agent}
        dateOne={dateOne}
        date2={date2}
        isLoding={isLoding}
      />
    </div>
  );
};

const Style = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: "10px 5px",
};
