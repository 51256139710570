import React from "react";
import { Table, Typography } from "antd";
import LodingText from "../../Loading/LodingText";
import OrderOneUserReport from "./OrderOneUserReport";
import css from "./daily.module.css";
import { numberFormat } from "../../../util/NumberFormat";

const { Title } = Typography;

// dukon_name: "Файз Фахриака Саттепо";
// izoh: "";
// sana: "19.01.2023 07:39:08";
// summa: "1500000";
// user: "Шарипов Маҳмуд";
const columns = [
  {
    title: "№",
    dataIndex: "id",
    width: 20,
    render: (_, rec, index) => {
      return index + 1;
    },
  },
  {
    title: "Sana",
    dataIndex: "sana",
  },
  {
    title: "Dukon nomi",
    dataIndex: "dukon_name",
  },
  {
    title: "Naqd",
    dataIndex: "summa",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.summa - b.summa,
    render: (_, record) => {
      return numberFormat(record.summa);
    },
  },
  {
    title: "Perechisleniye",
    dataIndex: "plastik",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.plastik - b.plastik,
    render: (_, record) => {
      return numberFormat(record.plastik);
    },
  },
  {
    title: "Karta",
    dataIndex: "karta",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.karta - b.karta,
    render: (_, record) => {
      return numberFormat(record.karta);
    },
  },

  {
    title: "Masul",
    dataIndex: "user",
    //  defaultSortOrder: "descend",
    sorter: (a, b) => a.user - b.user,
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
};

export const OrdersDailyTable2 = ({ dailyData }) => {
  //   console.log("dailyData", dailyData.debt_tulov);
  let TotalCash = 0;
  let TotalPlastik = 0;
  let TotalKarta = 0;
  dailyData.debt_tulov?.forEach(({ summa, karta, plastik }) => {
    let Summa = Number(summa);
    TotalPlastik += Number(plastik);
    TotalKarta += Number(karta);

    TotalCash += Summa;
  });
  //   //   console.log(agent);
  //   const DataAgent = agent.length !== 0 ? agent.map((i) => i.fio) : "Barchasi";

  if (!dailyData.debt_tulov) {
    return <LodingText />;
  }
  if (dailyData.debt_tulov.length === 0) {
    return <LodingText />;
  }
  //разделяем на отделные массивы каждого доставщика
  let OneUser = Object.values(
    dailyData.debt_tulov?.reduce((r, cur) => {
      const key = "k" + cur["user"]; // символ "k" добавлен, чтобы автоматически не сортировало по цифровым ключам
      (r[key] = r[key] || []).push(cur);

      return r;
    }, {})
  );
  //   console.log(OneUser);
  // передаём данные каждого доставщика в отдельные окна
  const OneUserReport = OneUser.map((i, index) => (
    <OrderOneUserReport key={index} data={i} />
  ));
  return (
    <>
      <div>
        <Title level={4}>Do'konlardan olingan summalar</Title>
        <div className={css.OrdersDailyTable2Header}>
          <div className={css.OrdersDailyTable2Header2}>
            <Title level={2}>Jammi naqd: {numberFormat(TotalCash)} </Title>
            <Title level={2}>
              Jammi perechisleniye: {numberFormat(TotalPlastik)}{" "}
            </Title>
            <Title level={2}>Jammi karta: {numberFormat(TotalKarta)} </Title>
          </div>
          <div className={css.OrdersDailyTable2Header2}> {OneUserReport}</div>
        </div>

        <Table
          rowKey={(row) => row.id}
          columns={columns}
          dataSource={dailyData.debt_tulov}
          onChange={onChange}
          pagination={false}
          style={{ fontSize: 20 }}
        />
      </div>
    </>
  );
};
