import dateFormat from "dateformat";
// export default function dateStringFormat(date) {
//   let d = new Date(+date * 1000);
//   const ds = d.toLocaleString("uz-UZ", {
//     day: "numeric",
//     month: "numeric",
//     year: "numeric",
//     minute: "numeric",
//     hour: "numeric",
//     second: "numeric",
//   });
//   return ds;
// }

export default function dateStringFormat(date) {
  if (!date) {
    return null;
  }
  return dateFormat(date * 1000, "dd/mm/yyyy");
}
