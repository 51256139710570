import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { items } from "../../state/menuItems";

export const SidebarMenu = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState("orders");
  const onLinkClick = (e) => {
    navigate(e.key);
    setCurrent(e.key);
  };
  return (
    <Menu
      theme="dark"
      mode="inline"
      items={items}
      defaultOpenKeys={[current]}
      onClick={onLinkClick}
    />
  );
};
