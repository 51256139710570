import ModalCenter from "Components/ModalComponent/ModalComponent/ModalCenter/ModalCenter";
import React from "react";
import StockFilter from "./StockFilter";
import { Divider } from "antd";
import StockFilterList from "./StockFilterList";

export default function StockFilterModal() {
  return (
    <ModalCenter name={"Kunlik kirgizilgan maxsulotlarni ko'rish"}>
      <StockFilter />
      <Divider />
      <StockFilterList />
    </ModalCenter>
  );
}
