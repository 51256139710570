import React, { useState, useRef } from "react";
import { OrdersReturnForm } from "./OrdersReturnForm";
import { OrdersReturnTable } from "./OrdersReturnTable";
import ReactToPrint from "react-to-print";
import { Button, Checkbox, Typography } from "antd";
import Loding from "../Loading/Loding";
import OrdersRetunList from "./OrdersRetunList";
import swal from "sweetalert";
import api from "http";

const { Title } = Typography;

export const OrdersReturn = () => {
  // получаем знвчения из ческбокса
  const [isValaue, setIsValue] = useState(false);

  // получаем данные одного агента
  const [eltuvchi, setEltuvchi] = useState([]);
  // получаем данные формы и передаем таблице
  const [interval, setInterval] = useState([]);
  // loding
  const [isLoding, setIsLoding] = useState(false);
  // даты
  const [dateOne, setDateOne] = useState("");
  const [date2, setDate2] = useState("");
  // ref для аечати
  //   console.log(dateOne, date2);
  const componentRef = useRef();

  // Запрос для таблицы рабочих
  const FetchUserSingle = (id) => {
    api
      .post("get-user-single", { id: id })
      .then(function (res) {
        if (res.status === 200) {
          setEltuvchi([res.data]);
          //  console.log("agent", res.data);
        }
      })
      .catch(function (error) {
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  const Style = {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 5px",
  };
  const checkValue = (check) => {
    setIsValue(check);
    setInterval([]);
  };
  return (
    <div>
      <Title level={4}>Do'konlar qaytargan maxsulotlar</Title>
      <div style={Style}>
        <OrdersReturnForm
          setInterval={setInterval}
          setIsLoding={setIsLoding}
          {...{ FetchUserSingle }}
          setDateOne={setDateOne}
          setDate2={setDate2}
          isValaue={isValaue}
          setIsValue={setIsValue}
        />
        <Checkbox onChange={(e) => checkValue(e.target.checked)}>
          Belgilang agar ma'lumotlar ro'yxat ko'rinishida kerak bo'lsa
        </Checkbox>
        <ReactToPrint
          trigger={() => (
            <Button type="primary" danger>
              Chop etish
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
      {isLoding === true ? (
        <Loding />
      ) : (
        <>
          {isValaue === false ? (
            <>
              <OrdersReturnTable
                interval={interval}
                componentRef={componentRef}
                eltuvchi={eltuvchi}
                dateOne={dateOne}
                date2={date2}
              />
            </>
          ) : (
            <OrdersRetunList
              interval={interval}
              componentRef={componentRef}
              eltuvchi={eltuvchi}
              dateOne={dateOne}
              date2={date2}
            />
          )}
        </>
      )}
    </div>
  );
};
