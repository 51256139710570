import { Button, Col, Form, Row, Select } from "antd";
import React from "react";
import useDeliveryBalansGoods from "stor/stock/useDeliveryBalansGoods";
import useUsers from "stor/user/useUsers";

const { Item } = Form;

export default function BalanceGoodsForm() {
  const { user } = useUsers();
  const { onFilter } = useDeliveryBalansGoods();
  const deliver = user.map((i) =>
    i.rol === "dostavka" && i.id !== 1 ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );

  return (
    <Form onFinish={onFilter} layout="vertical">
      <Row gutter={12}>
        <Col span={16}>
          <Item
            name="eltuvchi_id"
            label="Eltuvchini tanlang"
            rules={[
              {
                required: true,
                message: "Eltuvchini tanlang",
              },
            ]}
          >
            <Select>{deliver}</Select>
          </Item>
        </Col>
        <Col span={8}>
          <Item label="Saralash">
            <Button htmlType="submit" type="primary">
              Saralash
            </Button>
          </Item>
        </Col>
      </Row>
    </Form>
  );
}
