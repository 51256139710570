import {
  ADD_ISSUANCE_PRODUCT,
  DELETE_ISSUANCE_PRODUCT,
  DELETE_ISSUANCE_PRODUCT_ARR,
} from "types/types";

/* eslint-disable default-case */
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state, action) {
  switch (action?.type) {
    case ADD_ISSUANCE_PRODUCT:
      const items = state.filter(
        (item) => item.product_id === action.productId
      );
      if (items[0]) {
        state.forEach((element, index) => {
          if (element.product_id === action.productId) {
            state[index].count += action.counts;
          }
        });
        return [...state];
      } else {
        return [
          ...state,
          {
            product_id: action.productId,
            count: action.counts,
          },
        ];
      }
    case DELETE_ISSUANCE_PRODUCT:
      return state.filter((item) => item.product_id !== action.productId);
    case DELETE_ISSUANCE_PRODUCT_ARR:
      state = [];
      return state;
  }
}
