import React, { useState } from "react";
import { Button, Modal } from "antd";

export const ModalCenter = ({ name, children, fetchOrderItems, getItems }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
          getItems && fetchOrderItems();
        }}
      >
        {name}
      </Button>
      <Modal
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={"max-content"}
        footer
      >
        {children}
      </Modal>
    </>
  );
};
