import { Form, Input } from "antd";
import React from "react";

export default function CategoryAdd({ handleFinesh, form }) {
  return (
    <Form onFinish={handleFinesh} form={form} layout="vertical">
      <Form.Item
        name="name"
        label="Yangi toifa nomi"
        rules={[
          {
            required: true,
            message: "Avval nomini kiriting!",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
