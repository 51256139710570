import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Table } from "antd";
import { Typography } from "antd";
import Loding from "../Loading/Loding";
import LodingText from "../Loading/LodingText";
import { RollbackOutlined } from "@ant-design/icons";
import css from "../Order/StyleOrders.module.css";
import api from "http";
import { numberFormat } from "util/NumberFormat";

const { Title } = Typography;

export const OrdersReportOrderSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  // state загрузчик
  const [isLoding, seIsLoding] = useState(true);
  const [top] = useState("topLeft");
  // state для получения список заказов
  const [ordersReport, setOrdersReport] = useState([]);
  const GetSingleData = () => {
    api
      .post("get-shop-order-singl", { shop_id: id })
      .then(function (res) {
        setOrdersReport(res.data);
        //   console.log(res.data);
        seIsLoding(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetSingleData();
    // eslint-disable-next-line
  }, [id]);
  const columns = [
    {
      title: "Sana",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Do'kon nomi",
      dataIndex: "dukon_name",
      key: "dukon_name",
    },
    {
      title: "Summa",
      dataIndex: "summa",
      key: "summa",
      render: (_, record) => {
        return numberFormat(record.summa);
      },
    },
    {
      title: "Chegirma",
      dataIndex: "chegirma",
      key: "chegirma",
    },
    {
      title: "Sof summa",
      dataIndex: "yaipi_summa",
      key: "yaipi_summa",
      render: (_, record) => {
        return numberFormat(record.yaipi_summa);
      },
    },
    {
      title: "To'lov turlari",
      children: [
        {
          title: "Naqd",
          dataIndex: "naqd",
          key: "naqd",
          render: (_, record) => {
            return numberFormat(record.naqd);
          },
        },
        {
          title: "Bank",
          dataIndex: "plastik",
          key: "plastik",
          render: (_, record) => {
            return numberFormat(record.plastik);
          },
        },
        {
          title: "Karta",
          dataIndex: "karta",
          key: "karta",
          render: (_, record) => {
            return numberFormat(record.karta);
          },
        },
        {
          title: "Qolgan qarzi",
          dataIndex: "qarz",
          key: "qarz",
          render: (_, record) => {
            return numberFormat(record.qarz);
          },
        },
      ],
    },
    {
      title: "Mas'ul agent",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Eltuvchi",
      dataIndex: "eltuvchi",
      key: "eltuvchi",
    },
    //  {
    //    title: "Xolati",
    //    dataIndex: "status",
    //    key: "status",
    //    render: (_, record) => {
    //      if (record.status === "new") {
    //        return <Tag color="#f50">Yangi buyurtma</Tag>;
    //      } else if (record.status === "confirmed") {
    //        return <Tag color="#87d068">Qabul qilingan</Tag>;
    //      } else {
    //        return <Tag color="#008080">Berilgan</Tag>;
    //      }
    //    },
    //  },
  ];

  if (isLoding === true) {
    return <Loding />;
  }
  if (ordersReport.length === 0) {
    return (
      <>
        <Button onClick={goBack} type="primary" danger>
          <RollbackOutlined />
        </Button>
        <LodingText />
      </>
    );
  }

  return (
    <div>
      <div>
        {ordersReport?.map((i) => (
          <Title key={i.id} level={4}>
            {i.dukon_name}
          </Title>
        ))}
        <Button onClick={goBack} type="primary" danger>
          <RollbackOutlined />
        </Button>
      </div>

      <Table
        rowKey={(row) => row.id}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <table className={css.OrdersDetailedTable}>
              <thead>
                <tr>
                  <th>№</th>
                  <th>Nomi</th>
                  <th>Soni</th>
                  <th>Narxi</th>
                  <th>Summa</th>
                </tr>
              </thead>
              <tbody>
                {record.products.map((i, index) => (
                  <tr key={i.id} className={css.OrdersDetailedTr}>
                    <td className={css.OrdersDetailedTd}>{index + 1}</td>
                    <td className={css.OrdersDetailedTd}>{i.nomi}</td>
                    <td className={css.OrdersDetailedTd}>{i.soni}</td>
                    <td className={css.OrdersDetailedTd}>
                      {numberFormat(i.price)}
                    </td>
                    <td className={css.OrdersDetailedTd}>
                      {numberFormat(i.summa)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ),
          //  defaultExpandedRowKeys: ["3"],
        }}
        pagination={{
          position: [top],
          pageSize: "8",
        }}
        scroll={{
          x: 1124,
        }}
        dataSource={ordersReport}
      />
    </div>
  );
};
