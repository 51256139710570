import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Image, Form } from "antd";
import Loding from "../Loading/Loding";
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import UpdateModal from "./ProductUpdate/UpdateModal";
import { numberFormat } from "util/NumberFormat";
import { configs } from "config";
import { useProduct } from "stor/product/useProduct";

export const ProductList = ({ newData, category, loading }) => {
  const { deleteProduct, getProduct } = useProduct();
  // udate
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [isvalue, setValue] = useState([]);
  const showModal = (e) => {
    setOpen(true);
    setValue(e);
    form.setFieldsValue({
      id: e.id,
      name: e.name,
      soni: e.soni,
      price: e.price,
      category_id: e.category_id,
      birlik: e.birlik,
      img: e.img,
    });
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Rasmi",
      dataIndex: "img",
      width: 220,
      render: (_, record) => {
        return record.img === "" ? (
          <Image src="error" />
        ) : (
          <Image
            width={180}
            src={`${configs.baseUrl + record.img}`}
            alt="img"
          />
        );
      },
    },
    {
      title: "Mahsulot nomi",
      dataIndex: "name",
    },
    {
      title: "Soni",
      dataIndex: "soni",
    },
    {
      title: "O'lchov birligi",
      dataIndex: "birlik",
    },
    {
      title: "Narxi",
      dataIndex: "price",
      editable: true,
      render: (_, record) => {
        return numberFormat(record.price);
      },
    },
    {
      title: "Toifasi",
      dataIndex: "category_name",
    },
    {
      title: "Amallar",
      dataIndex: "operation",
      width: 150,
      render: (_, record) => {
        return (
          <div>
            <Row>
              <Col span={12}>
                <Button type="primary" ghost onClick={() => showModal(record)}>
                  <BiEditAlt />
                </Button>
              </Col>
              <Col span={4}>
                <Button danger onClick={() => deleteProduct(record.id)}>
                  {" "}
                  <RiDeleteBin5Line />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  let column = columns.map((item) => {
    return Object.assign(
      {
        sorter: (a, b) => {
          // a.dataIndex.length - b.dataIndex.length
          if (a[item.dataIndex] > b[item.dataIndex]) return 1;
          if (a[item.dataIndex] === b[item.dataIndex]) return 0;
          if (a[item.dataIndex] < b[item.dataIndex]) return -1;
        },
        sortDirections: ["descend", "ascend"],
      },
      item
    );
  });

  if (newData.length === 0) {
    return (
      <div style={Style}>
        <Loding />
      </div>
    );
  }
  return (
    <>
      <Table
        rowKey={(row) => row.id}
        bordered
        dataSource={newData}
        columns={column}
        rowClassName="editable-row"
        pagination={{
          position: ["topLeft"],
          //   pageSize: 50,
        }}
        scroll={{
          y: 600,
        }}
        loading={loading}
      />
      <UpdateModal
        open={open}
        setOpen={setOpen}
        isvalue={isvalue}
        form={form}
        category={category}
      />
    </>
  );
};
const Style = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
