import React, { useState, useEffect } from "react";
import { OrdersReportList } from "./OrdersReportList";
import { OrdersReportSerch } from "./OrdersReportSerch";
import { Typography } from "antd";
import api from "http";

const { Title } = Typography;
export const OrdersReport = () => {
  const [debtors, setDebtors] = useState([]);
  useEffect(() => {
    api
      .post("get-shop-hisobot")
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.filter((item) => item.balans < -1000);
          setDebtors(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   console.log(ordersReport);
  const [filtered, setFiltered] = useState([]);
  //   console.log(ordersReport);
  // функцыя для поиска данных

  useEffect(
    (_) => {
      setFiltered(debtors);
    },
    [debtors]
  );

  const Search = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = debtors;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.name.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = debtors;
    }
    setFiltered(newList);
  };

  return (
    <div>
      <div style={Style}>
        <Title level={4}> Qarzdorlar ro'yhati </Title>
        <OrdersReportSerch {...{ Search }} />
      </div>
      <OrdersReportList filtered={filtered} />
    </div>
  );
};

const Style = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};
