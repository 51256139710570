import { useState } from "react";
import { Button, Modal } from "antd";
import UpdateForm from "./UpdateForm";
import { useProduct } from "stor/product/useProduct";

const UpdateModal = ({ open, setOpen, isvalue, form, category }) => {
  const { updateProduct } = useProduct();
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
    form.setFieldsValue({
      name: "",
      soni: "",
      price: "",
      category_id: "",
      birlik: "",
      img: "",
    });
  };

  const handleFinesh = (value) => {
    updateProduct(value);
    handleOk();
  };

  return (
    <>
      <Modal
        open={open}
        title="Tahrirlash"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" danger onClick={handleCancel}>
            Yopish
          </Button>,
          <Button
            key="backс"
            type="primary"
            danger
            onClick={() => form.resetFields()}
          >
            O'chirish
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            O'zgartirish
          </Button>,
        ]}
      >
        <UpdateForm
          handleFinesh={handleFinesh}
          form={form}
          isvalue={isvalue}
          category={category}
        />
      </Modal>
    </>
  );
};
export default UpdateModal;
