import React, { useRef, useState } from "react";
import DrowerModal from "../ModalComponent/ModalComponent/DrowerModal/DrowerModal";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { PatternFormat } from "react-number-format";
import swal from "sweetalert";
import css from "./Users.module.css";
import useUsers from "stor/user/useUsers";

const EditWorking = ({ id, name, pasport, balans, login, parol }) => {
  const { onEdit } = useUsers();

  // state для получения значения изи Pattern format
  const [getTel, setGetTel] = useState(pasport);

  const RefName = useRef();
  const RefRol = useRef();
  const RefBalans = useRef();
  const RefParol = useRef();
  const RefLogin = useRef();

  const FetchEdit = () => {
    const data = {
      fio: RefName.current.value,
      pasport: getTel,
      rol: RefRol.current.value,
      balans: RefBalans.current.value,
      login: RefLogin.current.value,
      password: RefParol.current.value,
      id: id,
    };

    if (RefRol.current.value === "default") {
      swal({
        title: "Ma'lumotlarni to'liq kiriting!",
        icon: "error",
        buttons: "Ok",
      });
      return null;
    } else onEdit(data);
  };

  return (
    <DrowerModal name={<EditOutlined title="O'zgartirish" />}>
      <h1>Xodimni ma'lumotlarini taxrirlash</h1>

      <div className={css.EditWorkingContainer}>
        <div>
          <label style={{ color: "#001529" }}>Ismi</label>
          <input
            type="text"
            defaultValue={name}
            className={css.clientAddInp}
            ref={RefName}
          />
        </div>
        <div>
          <label style={{ color: "#001529" }}>Pasport seriya raqami</label>

          <PatternFormat
            value={pasport}
            format="+998 (##) ### ## ##"
            allowEmptyFormatting
            mask="_"
            className={css.clientAddInp}
            onChange={(event) => setGetTel(event.target.value)}
          />
        </div>
        <div>
          <label style={{ color: "#001529" }}>Balansi</label>
          <input
            type="number"
            defaultValue={balans}
            className={css.clientAddInp}
            ref={RefBalans}
          />
        </div>

        <div>
          <label style={{ color: "#001529" }}>Mansabi</label>
          <select
            style={{ width: "100%" }}
            className={css.clientAddInp}
            defaultValue={"default"}
            ref={RefRol}
          >
            <option disabled value={"default"}>
              Xodimni mansabini tanlang
            </option>
            <option value="admin">Admin</option>
            <option value="manager">Menedjer</option>
            <option value="dostavka">Eltuvchi</option>
            <option value="agent">Agent</option>
            {/* {getStatus.map((k) => (
              <option key={k.id} value={k.id}>
                {k.name}
              </option>
            ))} */}
          </select>
        </div>
        <div>
          <label style={{ color: "#001529" }}>Login</label>
          <input
            type="text"
            defaultValue={login}
            className={css.clientAddInp}
            ref={RefLogin}
          />
        </div>
        <div>
          <label style={{ color: "#001529" }}>Parol</label>
          <input type="text" className={css.clientAddInp} ref={RefParol} />
        </div>
      </div>
      <div style={{ paddingTop: 20 }}>
        <Button type="submit" onClick={FetchEdit} className={css.StyleBtnAdd}>
          O'zgartirish
        </Button>
      </div>
    </DrowerModal>
  );
};

export default EditWorking;
