import axios from "axios";
import { configs } from "config";

const api = axios.create({
  baseURL: configs.baseUrl,
  responseType: "json",
});

api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("_auth")}`;
  return config;
});

export default api;
