import React, { useState, useEffect } from "react";
import { ProductAdd } from "./ProductAdd";
import { ProductList } from "./ProductList";
import { ProductSerch } from "./ProductSerch";
import { Typography } from "antd";
import useCategory from "../../Provider/Category/useCategory";
import ProductCategorySort from "./ProductCategorySort";
import { useProduct } from "stor/product/useProduct";
const { Title } = Typography;

export const Product = () => {
  const { category } = useCategory();
  const { product, loading } = useProduct();

  // сорт по категории
  const [sort, setSort] = useState([]);
  useEffect(
    (_) => {
      // после загрузки данных из базы передаём его внаш стате
      setSort(product);
    },
    [product]
  );
  // функцыя для сорта по категориям
  const handleCategory = (id) => {
    //  console.log(id);
    if (id !== "0") {
      const newSort = product.filter((i) => i.category_id === id);
      return setSort(newSort);
    }
    return setSort(product);
  };

  // функцыя для поиска данных
  const [filtered, setFiltered] = useState([]);
  useEffect(
    (_) => {
      setFiltered(sort);
    },
    [sort]
  );
  const searchPoisk = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = sort;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.name.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = sort;
    }
    setFiltered(newList);
  };

  return (
    <>
      <Title level={4}>Mahsulotlar</Title>
      <div style={{ width: "100%" }}>
        <div style={Styles}>
          <ProductAdd category={category} />
          <div>
            <ProductCategorySort
              category={category}
              handleCategory={handleCategory}
            />
            <ProductSerch {...{ searchPoisk }} />
          </div>
        </div>
        <ProductList loading={loading} newData={filtered} category={category} />
      </div>
    </>
  );
};

const Styles = {
  padding: "15px 0px",
  display: "flex",
  justifyContent: "space-between",
};
