import { createContext } from "react";

export const SummaryReport = createContext(null);
export const Stock = createContext(null);
export const Issuance = createContext(null);
export const Product = createContext(null);
export const Users = createContext(null);
export const BalansGoods = createContext(null);
export const Shops = createContext(null);
export const Sms = createContext(null);
