import React, { useCallback, useEffect, useState, useRef } from "react";
import { OrderNewList } from "./OrderNewList";
import { Typography, Button } from "antd";
import { OrdersSearch } from "./OrdersSearch";
import { TabsProvider } from "../../Provider/StoreProvider";
import Loding from "../Loading/Loding";
import ReactToPrint from "react-to-print";
import { OrdersSinglAgent } from "./OrdersSingleAgent/OrdersSinglAgent";
import api from "http";

const { Title } = Typography;

export const Orders = () => {
  const componentRef = useRef();
  // state для Загрузки
  const [isLoading, setIsLoading] = useState(false);
  // state для получения список заказов
  const [ordersNew, setOrdersNew] = useState([]);

  // получаем список заказов
  const GetOrders = useCallback(() => {
    api
      .post("get-orders")
      .then(function (res) {
        const OrdersData = res.data;
        OrdersData.reverse();
        setOrdersNew(OrdersData);
        setIsLoading(true);
        //   console.log("1", res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [setOrdersNew]);

  useEffect(() => {
    GetOrders();
  }, [GetOrders]);

  const [filtered, setFiltered] = useState([]);
  // функцыя для поиска данных
  useEffect(
    (_) => {
      setFiltered(ordersNew);
    },
    [ordersNew]
  );

  const Search = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = ordersNew;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.dukon_name.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = ordersNew;
    }
    setFiltered(newList);
  };

  const Styles = {
    padding: "15px 0px",
    display: "flex",
    justifyContent: "space-between",
  };

  return (
    <div>
      <Title level={4}>Buyurtmalar ro'yhati</Title>
      <TabsProvider.Provider value={GetOrders}>
        <div style={Styles}>
          {/* <NewOrdersAdd dukonlar={dukonlar} product={product} /> */}
          <OrdersSearch {...{ Search }} />
          <div>
            {/* филтер для просмотра заказов по агентам */}
            <OrdersSinglAgent />
            {"\xa0\xa0\xa0"}
            <ReactToPrint
              trigger={() => (
                <Button type="primary" danger>
                  Chop etish
                </Button>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
        {isLoading === false ? (
          <Loding />
        ) : (
          <OrderNewList filtered={filtered} componentRef={componentRef} />
        )}
      </TabsProvider.Provider>
    </div>
  );
};
