import React, { useState, useEffect } from "react";
import { OrdersDailyForm } from "./OrdersDailyForm";
import { OrdersDailyTable } from "./OrdersDailyTable";
import { OrdersDailyTable2 } from "./OrdersDailyTable2";
import { OrdersDailyTable3 } from "./OrdersDailyTable3";
import { Typography } from "antd";
import css from "./daily.module.css";
import Loding from "../../Loading/Loding";
import api from "http";

const { Title } = Typography;

export const OrdersDaily = () => {
  // state для получения данныз из формы DailyForm
  const [isLoding, setIsLoding] = useState(false);
  // state для получения данныз из формы DailyForm
  const [dailyData, setDailyData] = useState([]);
  // state для имен рабочих
  const [userData, setUserData] = useState([]);
  // получаем данные магазинов
  const GetTotalShop = () => {
    api
      .post("shops-get")
      .then(function (res) {
        setUserData(res.data);
        //   setIsLoading(true);
        //   console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetTotalShop();
    // eslint-disable-next-line
  }, [dailyData]);

  return (
    <div>
      <div className={css.blockDaiyliHeader}>
        <Title level={4}>Buyurtmalardan tushumlar</Title>
        <OrdersDailyForm
          userData={userData}
          setDailyData={setDailyData}
          setIsLoding={setIsLoding}
        />
      </div>
      {isLoding === true ? (
        <Loding />
      ) : (
        <>
          {" "}
          <OrdersDailyTable dailyData={dailyData} />
          <OrdersDailyTable3 dailyData={dailyData} />
          <OrdersDailyTable2 dailyData={dailyData} />
        </>
      )}
    </div>
  );
};
