import { Form, Input } from "antd";
import React from "react";

export default function CategotyUpdate(props) {
  const { form, handleFinesh } = props;
  return (
    <>
      <Form onFinish={handleFinesh} form={form}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="name" label="Toifa nomi">
          <Input />
        </Form.Item>
      </Form>
    </>
  );
}
