import { Button, Col, InputNumber, Row, Select } from "antd";
import Item from "antd/es/list/Item";
import React from "react";

export default function StockAddProduct(props) {
  const { setProduncts, value, data, count, setCount, onProduct } = props;
  return (
    <Row gutter={8}>
      <Col flex={4}>
        <Select
          allowClear
          // onSelect={""}
          onChange={(e) => setProduncts(e)}
          showSearch
          style={{
            width: "100%",
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          value={value}
          options={data.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />
      </Col>
      <Col flex={4}>
        <InputNumber
          value={count}
          onChange={(counts) => setCount(counts)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col flex={2}>
        <Item>
          <Button
            style={{ width: "100%" }}
            onClick={onProduct}
            type="dashed"
            danger
            disabled={value !== "" && count !== "" ? false : true}
          >
            Qo`shish
          </Button>
        </Item>
      </Col>
    </Row>
  );
}
