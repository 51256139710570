import React, { useState } from "react";
import { ModalCenter } from "../ModalComponent/ModalCenter";
import { Space, Table, Typography } from "antd";
import { numberFormat } from "../../util/NumberFormat";
import { OrdersPrint } from "./OrdersPrint";
import api from "http";
const { Title } = Typography;

export const OrdersItem = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  function fetchOrderItems() {
    setLoading(true);
    api
      .post("get-orders-item", { id: record.id })
      .then(function (res) {
        setItems(res.data);
      })
      .catch(function (error) {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (_, rec, index) => {
        return index + 1;
      },
    },
    {
      title: "Nomi",
      dataIndex: "nomi",
      key: "nomi",
    },
    {
      title: "Soni",
      dataIndex: "soni",
      key: "soni",
    },
    {
      title: "Narxi",
      dataIndex: "price",
      key: "price",
      record: (_, rec) => {
        return numberFormat(rec.price);
      },
    },
    {
      title: "Summa",
      dataIndex: "summa",
      key: "summa",
      record: (_, rec) => {
        return numberFormat(rec.summa);
      },
    },
  ];

  let column = columns.map((item) => {
    return Object.assign(
      {
        sorter: (a, b) => {
          // a.dataIndex.length - b.dataIndex.length
          if (a[item.dataIndex] > b[item.dataIndex]) return 1;
          if (a[item.dataIndex] === b[item.dataIndex]) return 0;
          if (a[item.dataIndex] < b[item.dataIndex]) return -1;
        },
        sortDirections: ["descend", "ascend"],
      },
      item
    );
  });
  return (
    <ModalCenter name="Batafsil" getItems={true} {...{ fetchOrderItems }}>
      <Title level={4}>{record.d_name}</Title>
      <Space style={{ marginBottom: 15 }} direction="horizontal">
        <OrdersPrint ordersData={record} items={items} />
      </Space>
      <Table
        rowKey={(row) => row.id}
        columns={column}
        scroll={{
          x: 1124,
        }}
        dataSource={items}
        pagination={false}
        loading={loading}
      />
    </ModalCenter>
  );
};
