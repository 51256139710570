import React from "react";
import LodingText from "../../Loading/LodingText";
import css from "./daily.module.css";
import { numberFormat } from "util/NumberFormat";

export const OrdersDailyTable = ({ dailyData }) => {
  //   console.log("table", dailyData.debt_tulov);
  if (!dailyData.orders_report) {
    return <LodingText />;
  }
  if (dailyData["orders_report"].length === 0) {
    return <LodingText />;
  }

  // выводим данные в таблицу
  const daily = (
    <>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Karta</td>
        <td className={css.dayliTableTd}>
          {numberFormat(dailyData.orders_report?.jami_karta) ?? 0}
        </td>
      </tr>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Naqd</td>
        <td className={css.dayliTableTd}>
          {numberFormat(dailyData.orders_report?.jami_naqd) ?? 0}
        </td>
      </tr>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Perechisleniye</td>
        <td className={css.dayliTableTd}>
          {numberFormat(dailyData.orders_report?.jami_plastik) ?? 0}
        </td>
      </tr>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Qarz</td>
        <td className={css.dayliTableTd}>
          {numberFormat(dailyData.orders_report?.jami_qarz) ?? 0}
        </td>
      </tr>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Buyurtmalar summasi</td>
        <td className={css.dayliTableTd}>
          {numberFormat(dailyData.orders_report?.jami_summa)}
        </td>
      </tr>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Chegirma summasi</td>
        <td className={css.dayliTableTd}>
          {numberFormat(
            dailyData.orders_report?.jami_summa -
              dailyData.orders_report?.jami_total_skidka
          )}
        </td>
      </tr>
      <tr className={css.dayliTableTr}>
        <td className={css.dayliTableTd}>Sof summa</td>
        <td className={css.dayliTableTd}>
          {numberFormat(dailyData.orders_report?.jami_total_skidka)}
        </td>
      </tr>
    </>
  );

  return (
    <div>
      <table className={css.dailyTable}>
        <thead>
          <tr>
            <th className={css.dayliTableTh}>To'lov turi</th>
            <th className={css.dayliTableTh}>Summa</th>
          </tr>
        </thead>
        <tbody>{daily}</tbody>
      </table>
    </div>
  );
};
