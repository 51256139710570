import React, { useContext } from "react";
import { TabsProvider } from "../../../Provider/StoreProvider";
import swal from "sweetalert";
import { TbTruckDelivery } from "react-icons/tb";
import { Button, Form, Select } from "antd";
import { Popover } from "antd";
import api from "http";

export const OrdersTransfer = ({ ordersData }) => {
  // state для получение имён рабочих
  const { GetOrders, users } = useContext(TabsProvider);
  // Запрос для имён рабочих

  // передаём заказ даставщику
  const OrdersTransfer = (value) => {
    value.order_id = ordersData.id;
    swal({
      title: "Ishonchingiz komilmi",
      // text: `Таблица будет удалён со всеми данными!`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post("add-eltuvchi-order", value)
          .then(function (res) {
            swal({
              title: `${res.data.xabar}`,
              icon: "success",
              button: "Ок",
            });
            GetOrders();
          })
          .catch(function (error) {
            swal({
              title: `${error}`,
              icon: "warning",
              button: "Ок",
            });
            GetOrders();
          });
      }
    });
  };
  // выводим имена агентов
  const dostavka = users.map((i) =>
    i.rol === "dostavka" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  const text = <span>Eltuvchiga o`tkazish</span>;
  const content = (
    <div>
      <Form
        name="time_related_controls"
        onFinish={OrdersTransfer}
        layout="vertical"
      >
        <Form.Item
          name="eltuvchi_id"
          rules={[
            {
              required: true,
              message: "Eltuvchini tanlang",
            },
          ]}
        >
          <Select placeholder="Eltuvchini tanlang">{dostavka}</Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 5, padding: 0, width: "100%" }}
          >
            Ok
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    //  <ModalCenter name={<TbTruckDelivery style={{ fontSize: 18 }} />}>
    //    <Form
    //      name="time_related_controls"
    //      onFinish={OrdersTransfer}
    //      layout="vertical"
    //    >
    //      <Form.Item label="Agentni tanlang" name="agent_id">
    //        <Select placeholder="Eltuvchini tanlang">{dostavka}</Select>
    //      </Form.Item>
    //      <Form.Item>
    //        <Button
    //          type="primary"
    //          htmlType="submit"
    //          style={{ marginLeft: 5, padding: 0, width: 100 }}
    //        >
    //          Yuborish
    //        </Button>
    //      </Form.Item>
    //    </Form>
    //  </ModalCenter>
    <Popover placement="top" title={text} content={content} trigger="click">
      <Button type="primary">
        <TbTruckDelivery style={{ fontSize: 18 }} />
      </Button>
    </Popover>
  );
};
