import React from "react";
import { Input } from "antd";
export const OrdersSearch = (props) => {
  return (
    <Input
      onChange={({ target: { value } }) => props.Search(value)}
      type="text"
      placeholder="Qidirish..."
      style={{ width: "20%" }}
    />
  );
};
