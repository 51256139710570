import React from "react";
import { Input } from "antd";
export const ShopSerch = (props) => {
  return (
    <Input
      onChange={({ target: { value } }) => props.searchPoisk(value)}
      type="text"
      placeholder="Qidirish..."
    />
  );
};
