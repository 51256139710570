import { Button, Col, Row, Select } from "antd";
import React from "react";
import useUsers from "stor/user/useUsers";

export default function StockIssuanceForm({
  postProduct,
  setDeliver,
  delivers,
}) {
  const { user } = useUsers();
  const deliver = user.map((i) =>
    i.rol === "dostavka" && i.id !== 1 ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );
  return (
    <Row justify="end">
      <Col span={8}>
        <Select
          onSelect={(e) => setDeliver(e)}
          style={{ width: "100%" }}
          placeholder="Eltuvchini tanlang"
        >
          {deliver}
        </Select>
      </Col>
      <Col>
        <Button
          disabled={delivers === "" ? true : false}
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 5, padding: 0, width: 100 }}
          onClick={() => postProduct()}
        >
          Saqlash
        </Button>
      </Col>
    </Row>
  );
}
