import React, { useEffect, useState } from "react";
import { ModalCenter } from "../../ModalComponent/ModalCenter";
import { Typography } from "antd";
import OrdersSingleAgentForm from "./OrdersSingleAgentForm";
import { OrdersSingleAgentTable } from "./OrdersSingleAgentTable";
import Loding from "../../Loading/Loding";
import api from "http";
const { Title } = Typography;

export const OrdersSinglAgent = () => {
  // state для загрузки
  const [isLoding, setIsLoding] = useState(false);
  // получаем данные из формы
  const [interval, setInterval] = useState([]);
  // state для имен рабочих
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    api
      .post("get-users")
      .then(function (res) {
        if (res.status === 200) {
          setUserData(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <ModalCenter name={"Saralash"}>
      <Title level={4}>Agent bo'yicha buyurtmalarni saralash</Title>
      <OrdersSingleAgentForm
        userData={userData}
        setInterval={setInterval}
        setIsLoding={setIsLoding}
      />
      {isLoding === true ? (
        <Loding />
      ) : (
        <OrdersSingleAgentTable interval={interval} />
      )}
    </ModalCenter>
  );
};
