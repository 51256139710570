import React from "react";
import { Typography } from "antd";
import { numberFormat } from "util/NumberFormat";
const { Title } = Typography;

const OrderOneUserReport = ({ data }) => {
  let TotalCash = 0;
  let TotalPlastik = 0;
  let TotalKarta = 0;
  let Name;
  data?.forEach(({ summa, user, plastik, karta }) => {
    let Summa = Number(summa);
    Name = user;
    TotalCash += Summa;
    TotalKarta += Number(karta);
    TotalPlastik += Number(plastik);
  });
  return (
    <Title level={5}>
      {Name + "\xa0\xa0"}Naqd: {"\xa0"}
      {TotalCash !== null ? numberFormat(TotalCash) : "0 \xa0\xa0"}
      {"\xa0\xa0"} Perechisleniye: {"\xa0"}
      {TotalPlastik !== null ? numberFormat(TotalPlastik) : "0"} {"\xa0\xa0"}
      Karta: {"\xa0"}
      {TotalKarta !== null ? numberFormat(TotalKarta) : "0"}
    </Title>
  );
};

export default OrderOneUserReport;
