import React, { useEffect, useState } from "react";
import { OrdersConfirmedSearch } from "./OrdersConfirmedSearch";
import { OrderConfiredList } from "./OrderConfiredList";
import { Typography } from "antd";
import { TabsProvider } from "../../Provider/StoreProvider";
import Loding from "../Loading/Loding";
import api from "http";

const { Title } = Typography;

export const OrdersConfirmed = () => {
  const [users, setUsers] = useState([]);
  // state для получения список заказов
  const [ordersNew, setOrdersNew] = useState([]);
  // state для LODING
  const [isLoading, setIsLoading] = useState(false);
  const FetchUserData = () => {
    api
      .post("get-users")
      .then(function (res) {
        if (res.status === 200) {
          setUsers(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    FetchUserData();
    // eslint-disable-next-line
  }, []);

  // получаем список заказов
  const GetOrders = () => {
    api
      .post("get-orders-confirm")
      .then(function (res) {
        // const OrdersData = res.data;
        // OrdersData.reverse();
        setOrdersNew(res.data);
        setIsLoading(true);
        //   console.log("1", res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetOrders();
    // eslint-disable-next-line
  }, []);
  //   console.log(ordersNew);
  const [filtered, setFiltered] = useState([]);

  // функцыя для поиска данных

  useEffect(
    (_) => {
      setFiltered(ordersNew);
    },
    [ordersNew]
  );

  const Search = (val) => {
    //текущие задачи и новые отфильтрованные задачи
    let currentTodos = [],
      newList = [];
    if (val !== "") {
      //делаем копию нашего стейта
      currentTodos = ordersNew;
      //фильтруем стейт в поисках совпадений
      newList = currentTodos.filter((todo) => {
        // значение которое пользователь ввел и которое у нас
        // в стейте делаем строчными буквами чтобы конфликтов не было
        // мало ли пользователь ввель строчными буквами а у нас заглавные
        const lc = todo.d_name?.toLowerCase();
        const filter = val.toLowerCase();
        // проверяем есть ли у нас этот элемент если есть возвращаем его
        return lc?.includes(filter);
      });
    } else {
      // если в input ничего нету то есть пользователь стер то
      // что ввел тогда возвращаем все задачи
      newList = ordersNew;
    }
    setFiltered(newList);
  };

  return (
    <div>
      <TabsProvider.Provider value={{ GetOrders, users }}>
        <div style={Style}>
          <Title level={4}>Qabul qilingan buyurtmalar ro'yhati</Title>
          <OrdersConfirmedSearch {...{ Search }} />
        </div>
        {isLoading === false ? (
          <Loding />
        ) : (
          <OrderConfiredList filtered={filtered} />
        )}
      </TabsProvider.Provider>
    </div>
  );
};

const Style = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};
