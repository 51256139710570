import React, { useEffect } from "react";
import { Button, Col, DatePicker, Form, Row, Select, Typography } from "antd";
import swal from "sweetalert";
import { ModalCenter } from "../ModalComponent/ModalCenter";
import api from "http";
import useUsers from "stor/user/useUsers";

const { Title } = Typography;

export const OrdersDeliverFilterForm = ({
  setInterval,
  setIsLoding,
  FetchUserSingle,
  setDateOne,
  setDate2,
}) => {
  const { user } = useUsers();

  //   console.log("user", userData);
  // выводим имена агентов
  const Agent = user.map((i) =>
    i.rol === "dostavka" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );

  // запрос данных
  const IntervalData = (value) => {
    setIsLoding(true);
    value = {
      ...value,
      sana1: value["sana1"].format("YYYY-MM-DD"),
      sana2: value["sana2"].format("YYYY-MM-DD"),
    };
    setDateOne(value.sana1);
    setDate2(value.sana2);
    api
      .post("get-hisobot-eltuvchi", value)
      .then(function (res) {
        if (res.status === 200) {
          setInterval(res.data);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      })
      .finally(() => {
        setIsLoding(false);
      });
  };

  // запрос данных
  const IntervalDataEffect = (value) => {
    let sana = new Date().getDate();
    let oy = new Date().getMonth().toString() + 1;
    let yil = new Date().getFullYear();
    let sana2 = new Date().getDate() + 1;
    let oy2 = new Date().getMonth().toString() + 1;
    let yil2 = new Date().getFullYear();
    value = {
      ...value,
      sana1: yil + "-" + oy + "-" + sana,
      sana2: yil2 + "-" + oy2 + "-" + sana2,
    };
    value.eltuvchi_id = "0";
    api
      .post("get-hisobot-eltuvchi", value)
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          setInterval(res.data);
          setIsLoding(true);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  useEffect(() => {
    IntervalDataEffect();
    // eslint-disable-next-line
  }, []);

  return (
    <ModalCenter name="Kunlik ro'yxatni ko'rish">
      <Title level={5}>
        Kunlik ma'lumotlarni ko'rish uchun sana va agentni tanlang
      </Title>
      <Form
        name="time_related_controls"
        onFinish={IntervalData}
        layout="vertical"
      >
        <div>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Boshlang'ich vaqti"
                name="sana1"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={"bottomRight"}
                  placeholder="Boshlang'ich vaqti"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tugash vaqti"
                name="sana2"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={"bottomRight"}
                  style={{ marginLeft: 5, width: "100%" }}
                  placeholder="Tugash vaqti"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Agentni tanlang" name="eltuvchi_id">
            <Select onChange={(e) => FetchUserSingle(e)}>
              <Select.Option Selected value="0">
                Barchasi
              </Select.Option>
              {Agent}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5, padding: 0, width: 100 }}
            >
              Yuborish
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ModalCenter>
  );
};
