import { useStock } from "stor/stock/useStock";
import StockList from "Components/custom/List";
import { useProduct } from "stor/product/useProduct";
import StockAddProduct from "Components/custom/StockAddProduct";
import { Button, Divider, Row, Space } from "antd";
import StockFilterModal from "./StockFilterModal";

export default function StockProduct() {
  const {
    onDelete,
    dataList,
    postProduct,
    loading,
    state,
    onProduct,
    setProduncts,
    setCount,
    product_id,
    count,
  } = useStock();
  const { product } = useProduct();
  return (
    <div>
      <Row justify="space-between">
        <h1>Tayyor maxsulot qo`shish</h1>
        <Space>
          <StockFilterModal />
        </Space>
      </Row>
      <Divider />
      <StockAddProduct
        setProduncts={setProduncts}
        value={product_id}
        data={product}
        count={count}
        setCount={setCount}
        onProduct={onProduct}
      />
      <Divider />
      <StockList
        footerComponent={
          <Row justify="end">
            <Button
              disabled={state.length !== 0 ? false : true}
              type="primary"
              onClick={() => postProduct()}
              loading={loading}
            >
              Malumotlarni saqlash
            </Button>{" "}
          </Row>
        }
        deletes={true}
        loading={loading}
        dataList={dataList}
        onDelete={onDelete}
      />
    </div>
  );
}
