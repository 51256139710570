import React from "react";
import { Button, Form, Input, Select } from "antd";
import ModalCenter from "../ModalComponent/ModalComponent/ModalCenter/ModalCenter";
import { PatternFormat } from "react-number-format";

export const UserNewAdd = ({ onAddUser, form }) => {
  return (
    <ModalCenter name="Xodim qo'shish">
      <h1>Yangi xomini ishga qabul qilish</h1>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onAddUser}
        autoComplete="off"
      >
        <Form.Item
          label="Ismi"
          name="fio"
          rules={[
            {
              required: true,
              message: "Ismini kriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Telefon raqami"
          name="pasport"
          rules={[
            {
              required: true,
              message: "Hodimni telefon raqamini kiriting",
            },
          ]}
        >
          <PatternFormat
            format="+998 (##) ### ## ##"
            allowEmptyFormatting
            mask="_"
            customInput={Input}
          />
        </Form.Item>
        <Form.Item label="Xodimni mansabini tanlang" name={"rol"}>
          <Select>
            <Select.Option value="admin">Admin</Select.Option>
            <Select.Option value="manager">Menedjer</Select.Option>
            <Select.Option value="dostavka">Eltuvchi</Select.Option>
            <Select.Option value="agent">Agent</Select.Option>
            <Select.Option value="sklad">Skladchi</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Balansi " name="balans">
          <Input />
        </Form.Item>
        <Form.Item
          label="Login biriktiring"
          name="login"
          rules={[
            {
              required: true,
              message: "Yangi login kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Parol biriktiring"
          name="password"
          rules={[
            {
              required: true,
              message: "Yangi parol kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Qabul qilish
          </Button>
        </Form.Item>
      </Form>
    </ModalCenter>
  );
};
