import React from "react";
import { Typography } from "antd";
import { numberFormat } from "util/NumberFormat";

export const OrderAccounting = ({ data }) => {
  let Summa = 0;
  let Karta = 0;
  let Naqd = 0;
  let Bank = 0;
  let Total = 0;
  let Qarz = 0;
  let name;
  data.forEach(({ karta, naqd, plastik, qarz, total, eltuvchi }) => {
    Karta += Number(karta);
    Naqd += Number(naqd);
    Bank += Number(plastik);
    Total += Number(total);
    Qarz += Number(qarz);
    Summa = Karta + Naqd + Bank;
    name = eltuvchi;
  });
  return (
    <div>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Masul eltuvchi:{"\xa0\xa0" + name}
      </Typography.Title>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Jami olingan summa:{"\xa0\xa0"}
        {Summa !== null ? numberFormat(Summa) : 0}
      </Typography.Title>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Jami naqd:{"\xa0\xa0"}
        {Naqd !== null ? numberFormat(Naqd) : 0}
      </Typography.Title>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Jami bank:{"\xa0\xa0"}
        {Bank !== null ? numberFormat(Bank) : 0}
      </Typography.Title>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Jami karta:{"\xa0\xa0"}
        {Karta !== null ? numberFormat(Karta) : 0}
      </Typography.Title>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Qarz buyurtmalar summasi:
        {"\xa0\xa0"}
        {Qarz !== null ? numberFormat(Qarz) : "0"}
      </Typography.Title>
      <Typography.Title
        //  editable
        level={5}
        style={{
          margin: 0,
        }}
      >
        Jami berilgan buyurtmalar summasi:
        {"\xa0\xa0"}
        {Total !== null ? numberFormat(Total) : "0"}
      </Typography.Title>
    </div>
  );
};
