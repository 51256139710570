import { List } from "antd";
import React from "react";
import { useStock } from "stor/stock/useStock";
import Print from "util/Print";

export default function StockFilterList() {
  const { dayDataLoading, dailyData } = useStock();
  return (
    <Print title="">
      <List
        loading={dayDataLoading}
        bordered
        dataSource={dailyData}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={index + 1 + "  " + item.nomi} />
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0 }}>soni: {item.soni}</p>
            </div>
          </List.Item>
        )}
      />
    </Print>
  );
}
