import React, { useState } from "react";
import { CategoryContext } from "./CategoryContext";
import api from "http";
import swal from "sweetalert";

export default function CategoryState({ children }) {
  const [category, setCategory] = useState([]);
  const [loading, setLoadeng] = useState(true);
  // получаем список категории
  const GetCategory = () => {
    setLoadeng(false);
    api
      .post("category-get")
      .then(function (res) {
        if (res.status === 200) {
          setCategory(res.data);
          setLoadeng(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //! получаем данные из формы и передаём в БД
  const categoryUpdate = (value) => {
    api
      .post(`category/${value.id}`, value)
      .then((res) => {
        GetCategory();
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleyeCategory = (e) => {
    swal({
      title: "Ishonchingiz komilmi",
      // text: `Таблица будет удалён со всеми данными!`,
      icon: "warning",
      buttons: {
        cancel: "Yo'q!",
        catch: {
          text: "Ha",
          value: "Ok",
        },
      },
    }).then((willDelete) => {
      if (willDelete === "Ok") {
        api
          .post(`category-del/${e}`)
          .then((res) => {
            const cat = category.filter((item) => item.id !== e);
            setCategory(cat);
            swal({
              title: `Muvofaqiyatli`,
              icon: "success",
              button: "Ок",
            });
          })
          .catch((err) => {
            swal({
              title: `Ooops ${err}`,
              icon: "warning",
              button: "Ок",
            });
          });
      }
    });
  };
  return (
    <CategoryContext.Provider
      value={{ category, GetCategory, loading, deleyeCategory, categoryUpdate }}
    >
      {children}
    </CategoryContext.Provider>
  );
}
