import React, { useState } from "react";
import { Col, Row, Table, Typography } from "antd";
import { numberFormat } from "../../../util/NumberFormat";
// import LodingText from "../../Loading/LodingText";
import ActSwerkiForm from "./ActSwerkiForm";
import ActSwerkiSort from "./ActSwerkiSort";
import css from "./ActSverki.module.css";
import api from "http";
import dayjs from "dayjs";
import dateStringFormatDefault from "util/dateStringFormatDefault";

const { Text, Title } = Typography;

function ActSwerkiTable({ shop }) {
  const [shopName, setShopName] = useState([]);
  const [shopDate, setShopDate] = useState("");
  const [fineshData, setFineshData] = useState([]);
  const [newdata, setNewData] = useState([]);
  // получаем данные клиента из ActSwerkiForm
  const newData = (data) => {
    if (data.length !== 0) setNewData(Object.values(data?.table_data));
  };

  function conversionToSeconds(data) {
    const date = data.format("YYYY-MM-DD");
    const editTime = date.split("");
    editTime[8] = "0";
    editTime[9] = "1";
    const editTimeJoin = editTime.join("");
    const finishTime = dayjs(editTimeJoin).unix() + 86400;
    return finishTime;
  }

  // получаем дату и год из ActSwerkiSort
  const ActSort = (value) => {
    // передаём дату
    const unixStartDate = conversionToSeconds(value["monthStart"]);
    const unixEndDate = conversionToSeconds(value["monthEnd"]);
    setFineshData(
      //   филтруем данные
      newdata
        .filter((o) => o.strdate >= unixStartDate && o.strdate <= unixEndDate)
        .sort((a, b) => (a.strdate > b.strdate ? 1 : -1))
    );

    setShopDate({
      start: dateStringFormatDefault(unixStartDate),
      end: dateStringFormatDefault(unixEndDate),
    });
  };

  const shopSingle = (value) => {
    api
      .post(`shop-single/${value}`)
      .then(function (res) {
        if (res.status === 200) {
          setShopName(res.data);
          //  console.log(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const column = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Joriy qarzi",
      dataIndex: "start",
      key: "start",
      render: (_, record) => {
        return numberFormat(record.start);
      },
    },
    {
      title: "Sana",
      dataIndex: "sana",
      key: "sana",
    },
    //  {
    //    title: "Buyurtma summa",
    //    dataIndex: "order_summ",
    //    key: "order_summ",
    //    render: (_, record) => {
    //      return numberFormat(record.order_summ);
    //    },
    //  },
    //  {
    //    title: "Chegirma",
    //    dataIndex: "skidka",
    //    key: "skidka",
    //    render: (_, record) => {
    //      return numberFormat(record.skidka);
    //    },
    //  },
    {
      title: "Etkazib berilgan maxsulot summasi",
      dataIndex: "total_skidka",
      key: "total_skidka",
      render: (_, record) => {
        return numberFormat(record.total_skidka) ?? 0;
      },
    },

    {
      title: "To'langan summa",
      dataIndex: "pay_summ",
      key: "opay_summ",
      render: (_, record) => {
        return numberFormat(record.pay_summ) ?? 0;
      },
    },
    //  {
    //    title: "Qarz",
    //    dataIndex: "qarz",
    //    key: "qarz",
    //    render: (_, record) => {
    //      return numberFormat(record.qarz);
    //    },
    //  },
    {
      title: "Qoldiq",
      dataIndex: "end",
      key: "end",
      render: (_, record) => {
        return numberFormat(record.end);
      },
    },
  ];
  return (
    <>
      <Table
        rowClassName={css.ActSwerkiTable}
        title={() => {
          return (
            <>
              <Row
                justify="space-around"
                align="stretch"
                gutter={8}
                className={css.ActSwerkiTableHeader}
              >
                <Col span={6}>
                  <ActSwerkiForm
                    shop={shop}
                    newArr={newData}
                    shopSingle={shopSingle}
                  />
                </Col>
                <Col span={18}>
                  <ActSwerkiSort ActSort={ActSort} />
                </Col>
              </Row>
              <div className={css.ActSwerkiTableHeaderInfo}>
                {" "}
                <div>
                  <Title level={4}>Do'kon: {shopName.name}</Title>
                  <Title level={4}>
                    Joriy balans:{" "}
                    {fineshData[fineshData.length - 1]?.end > 0
                      ? "-" +
                        numberFormat(fineshData[fineshData.length - 1]?.end)
                      : numberFormat(fineshData[fineshData.length - 1]?.end) ??
                        0}{" "}
                    so'm
                  </Title>
                </div>
                <div>
                  <Title level={5}>
                    Boshlang'ich davr: {shopDate && shopDate.start} yil
                  </Title>
                  {""}
                  <Title level={5}>
                    Davr oxiri: {shopDate && shopDate.end} yil
                  </Title>
                </div>
              </div>
            </>
          );
        }}
        bordered
        rowKey={(row) => row.id}
        columns={column}
        dataSource={fineshData}
        pagination={false}
        sticky
        summary={(pageData) => {
          //  let totalZakaz = 0;
          let totalSkidka = 0;
          //  let totalSale = 0;
          //  let totalStart = 0;
          let totalNaqd = 0;
          //  let totalQarz = 0;
          let totalEnd = 0;
          pageData.forEach(
            ({
              start,
              order_summ,
              skidka,
              pay_summ,
              qarz,
              end,
              total_skidka,
              start_balance,
            }) => {
              //   totalStart = start;
              //   totalZakaz += Number(order_summ);
              totalSkidka += Number(total_skidka);
              //   totalSale += Number(skidka);
              totalNaqd += Number(pay_summ);
              //   totalQarz += Number(qarz);
              //   totalEnd =
              //     Number(ollData?.start_balance) + totalSkidka - totalNaqd;
              //   console.log(Number(ollData?.start_balance));
              //   console.log(totalSkidka);
              totalEnd = Number(pageData[pageData.length - 1].end);
            }
          );
          //  console.log(pageData);
          return (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                <Table.Summary.Cell
                  className={css.ActSwerkiTableSummary}
                  index={0}
                >
                  Jami:
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className={css.ActSwerkiTableSummary}
                  index={1}
                  colSpan={2}
                >
                  <Text> {numberFormat(pageData[0]?.start)}</Text>
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={3}>
                  <Text type="danger"> {numberFormat(totalZakaz)}</Text>
                </Table.Summary.Cell> */}
                {/* <Table.Summary.Cell index={4}>
                  <Text> {numberFormat(totalSale)}</Text>
                </Table.Summary.Cell> */}
                <Table.Summary.Cell
                  className={css.ActSwerkiTableSummary}
                  index={3}
                >
                  <Text type="danger"> {numberFormat(totalSkidka)}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className={css.ActSwerkiTableSummary}
                  index={4}
                >
                  <Text> {numberFormat(totalNaqd)}</Text>
                </Table.Summary.Cell>
                {/* <Table.Summary.Cell index={7}> */}
                {/* <Text>{numberFormat(totalQarz)}</Text> */}
                {/* </Table.Summary.Cell> */}
                <Table.Summary.Cell
                  className={css.ActSwerkiTableSummary}
                  index={5}
                >
                  <Text> {numberFormat(totalEnd)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </>
  );
}

export default ActSwerkiTable;
