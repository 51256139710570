import { useState } from "react";
import { Button, Modal } from "antd";
import { Form } from "antd";
import CategoryAdd from "./CategoryAdd";
import api from "http";

const CategoryAddModal = ({ GetCategory }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  // получаем данные из формы и передаём в БД
  const handleFinesh = (value) => {
    api
      .post("category-add", value)
      .then((res) => {
        GetCategory();
      })
      .catch((err) => {
        console.log(err);
      });
    handleOk();
  };

  return (
    <>
      <Button type="primary" danger onClick={showModal}>
        Yangi toifa qo'shish
      </Button>
      <Modal
        open={open}
        title="Окно для добавления нового товара"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" type="primary" danger onClick={handleCancel}>
            Yopish
          </Button>,
          <Button
            key="backс"
            type="primary"
            danger
            onClick={() => form.resetFields()}
          >
            O'chirish
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Ko'shish
          </Button>,
        ]}
      >
        <CategoryAdd handleFinesh={handleFinesh} form={form} />
      </Modal>
    </>
  );
};
export default CategoryAddModal;
