import { Users } from "Context/context";
import { useForm } from "antd/es/form/Form";
import {
  ARE_YOU_SURE,
  ATTENTION,
  ERROR,
  NO,
  OK,
  OOPS,
  SUCCESS,
  SUCCESSOK,
  SUCCESS_TEXT,
  SWAL_INFO,
  SWAL_OKTEXT,
  WARNING,
} from "constant/constants";
import api from "http";
import React, { useState } from "react";
import swal from "sweetalert";

export default function UserState({ children }) {
  const [form] = useForm();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  function getUser() {
    setLoading(true);
    api
      .post("get-users")
      .then(function (res) {
        if (res.status === 200) {
          setUser(res.data);
          //  console.log("WorkerList", res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const onAddUser = (values) => {
    setLoading(true);
    swal({
      title: ATTENTION,
      text: SWAL_INFO,
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post("user-add", values)
          .then(function (res) {
            if (res.status === 200) {
              swal({
                title: SUCCESSOK,
                icon: SUCCESS,
                button: OK,
              });
              form.resetFields();
              getUser();
            }
          })
          .catch(function (error) {
            setLoading(false);
          });
      } else setLoading(false);
    });
  };
  const onDeleteUser = (i) => {
    swal({
      title: ARE_YOU_SURE,
      // text: `Таблица будет удалён со всеми данными!`,
      icon: WARNING,
      buttons: {
        cancel: NO,
        catch: {
          text: SWAL_OKTEXT,
          value: OK,
        },
      },
    }).then((willDelete) => {
      if (willDelete === OK) {
        api
          .post("delete-user", { id: i })
          .then(function (response) {
            if (response.status === 200) {
              setUser((user) => user.filter((item) => item.id !== i));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };
  const onEdit = (data) => {
    api
      .post(`update-user/${data.id}`, data)
      .then(function (res) {
        if (res.status === 200) {
          swal({
            title: OK,
            text: SUCCESS_TEXT,
            icon: SUCCESS,
            buttons: OK,
          });
          getUser();
        }
      })
      .catch(function (error) {
        swal({
          title: OOPS,
          icon: ERROR,
          buttons: OK,
        });
        console.log(error);
      });
  };

  return (
    <Users.Provider
      value={{ getUser, user, loading, onDeleteUser, onAddUser, form, onEdit }}
    >
      {children}
    </Users.Provider>
  );
}
