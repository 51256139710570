import React, { useState } from "react";

import "./CssModal.css";

const ModalCenter = ({ children, name }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <button className="edit__modal-btn" onClick={() => setVisible(true)}>
        {name}
      </button>
      <div
        className={visible ? "edit-open-modal active" : "edit-open-modal"}
        onClick={() => setVisible(false)}
      >
        <section
          className="edit-modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </section>
      </div>
    </>
  );
};

export default ModalCenter;
