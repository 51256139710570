/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Typography,
} from "antd";
import swal from "sweetalert";
import { ModalCenter } from "../ModalComponent/ModalCenter";

import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";
import api from "http";

dayjs.extend(customParseFormat);

const { Title } = Typography;

export const OrdersReturnForm = ({
  setInterval,
  setIsLoding,
  FetchUserSingle,
  setDateOne,
  setDate2,
  setIsValue,
  isValaue,
}) => {
  let sana = new Date().getDate();
  let oy = new Date().getMonth() + 1;
  let yil = new Date().getFullYear();
  let sana2 = new Date().getDate() + 1;
  let yil2 = new Date().getFullYear();
  const dateFormat = "YYYY-MM-DD";
  // state для имен рабочих
  const [userData, setUserData] = useState([]);
  // меняем состояние чекбокса и обнуляем передаваемые данные через setInterval
  const checkValue = (check) => {
    setIsValue(check);
    setInterval([]);
  };

  // Запрос для имён рабочих
  const FetchUserData = () => {
    api
      .post("get-users")
      .then(function (res) {
        if (res.status === 200) {
          setUserData(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    FetchUserData();
    // eslint-disable-next-line
  }, []);

  // выводим имена доставщиков
  const Agent = userData.map((i) =>
    i.rol === "dostavka" ? (
      <Select.Option key={i.id} value={i.id}>
        {i.fio}
      </Select.Option>
    ) : null
  );

  const IntervalData = async (value) => {
    setIsLoding(true);
    value = {
      ...value,
      sana1: value["sana1"]?.format("YYYY-MM-DD"),
      sana2: value["sana2"]?.format("YYYY-MM-DD"),
    };
    setDateOne(value?.sana1);
    setDate2(value?.sana2);
    let url = isValaue === false ? "get-vozvrat" : "get-deliver-return-list";
    await api
      .post(url, value)
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          setIsLoding(false);
          setInterval(res.data);

          //  console.log("interval", res.data);
        } else if (res.error.code === "ERR_NETWORK") {
          swal({
            title: `Ошибка соединения!!!`,
            icon: "warning",
            button: "Спасибо",
          });
        }
        //   throw new Error("ERR_CONNECTION_TIMED_OUT!");
      })
      .catch(function (error) {
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };
  // запрос данных
  const IntervalDataEffect = (value) => {
    value = {
      ...value,
      sana1: yil + "-" + ("0" + oy).slice(-2) + "-" + ("0" + sana).slice(-2),
      sana2: yil2 + "-" + ("0" + oy).slice(-2) + "-" + ("0" + sana2),
    };
    setDateOne(value?.sana1);
    setDate2(value?.sana2);
    value.eltuvchi_id = 0;
    let url = isValaue === false ? "get-vozvrat" : "get-deliver-return-list";
    api
      .post(url, value)
      .then(function (res) {
        //   console.log(res);
        if (res.status === 200) {
          setInterval(res.data);
          setIsValue(isValaue);
          setIsLoding(false);
          //  console.log("interval", res.data);
        } else if (res.status !== 200) {
          swal({
            title: "Внимание!!!",
            text: " Ошибка соединения с интернетом!",
            icon: "warning",
            button: "Ок",
          });
        }
      })
      .catch(function (error) {
        setIsLoding(false);
        swal({
          title: `${error}`,
          icon: "warning",
          button: "Ок",
        });
      });
  };

  useEffect(() => {
    IntervalDataEffect();
    // eslint-disable-next-line
  }, [isValaue]);

  const [placement] = useState("bottomRight");

  return (
    <ModalCenter name="Ma'lumotlarni saralash">
      <Title level={5}>
        Kunlik ma'lumotlarni ko'rish uchun sana va agentni tanlang
      </Title>
      <Form
        name="time_related_controls"
        onFinish={IntervalData}
        layout="vertical"
        initialValues={{
          sana1: dayjs(
            yil + "-" + ("0" + oy).slice(-2) + "-" + ("0" + sana).slice(-2),
            dateFormat
          ),
          sana2: dayjs(
            yil2 + "-" + ("0" + oy).slice(-2) + "-" + ("0" + sana2),
            dateFormat
          ),
        }}
      >
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Boshlang'ich vaqti"
                name="sana1"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={placement}
                  placeholder="Boshlang'ich vaqti"
                  style={{ width: "100%" }}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tugash vaqti"
                name="sana2"
                rules={[
                  {
                    required: true,
                    message: "Sanani tanlang",
                  },
                ]}
              >
                <DatePicker
                  placement={placement}
                  style={{ marginLeft: 5, width: "100%" }}
                  placeholder="Tugash vaqti"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Eltuvchini tanlang" name="eltuvchi_id">
                <Select onChange={(e) => FetchUserSingle(e)}>
                  <Select.Option selected value={0}>
                    Barchasi
                  </Select.Option>
                  {Agent}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Qaytarilgan maxsulotlar ro'yxat ko'rinishida"
                wrapperCol={{
                  offset: 2,
                }}
                valuePropName={isValaue}
              >
                <Checkbox onChange={(e) => checkValue(e.target.checked)}>
                  Belgilang agar ma'lumotlar ro'yxat ko'rinishida kerak bo'lsa
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 5, padding: 0, width: 100 }}
            >
              Yuborish
            </Button>
          </Form.Item>
        </>
      </Form>
    </ModalCenter>
  );
};
