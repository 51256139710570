import { Button, Col, Row, Typography } from "antd";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

const { Title } = Typography;

export default function Print({ children, title }) {
  const componentRef = useRef();
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={4}>{title}</Title>
        </Col>
        <Col>
          <ReactToPrint
            trigger={() => (
              <Button type="primary" danger>
                Chop etish
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
      <div style={{ paddingTop: 10 }} ref={componentRef}>
        {children}
      </div>
    </div>
  );
}
