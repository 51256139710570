import React from "react";
import { List, Tabs } from "antd";
import useDeliveryBalansGoods from "stor/stock/useDeliveryBalansGoods";
import Print from "util/Print";

export default function BalanceGoodsList() {
  const { data, loading } = useDeliveryBalansGoods();
  return (
    <Tabs
      //       onChange={onChange}
      type="card"
      items={[
        {
          label: "Maxsulotlar",
          key: "asdasd",
          children: (
            <Print title="">
              <List
                loading={loading}
                bordered
                dataSource={data}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta title={index + 1 + "  " + item.nomi} />
                    <p style={{ margin: 0 }}>soni: {item.soni}</p>
                  </List.Item>
                )}
              />
            </Print>
          ),
        },
      ]}
    />
  );
}
