import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Typography } from "antd";
import Loding from "../Loading/Loding";
import LodingText from "../Loading/LodingText";
import { OrdersReportHisOrders } from "./OrdersReportHisOrders";
import { OrdersReportHisTulov } from "./OrdersReportHisTulov";
import { numberFormat } from "../../util/NumberFormat";
import ReactToPrint from "react-to-print";
import api from "http";

export const OrdersReportHisSingle = ({ modalGotov }) => {
  const [ordersReport, setOrdersReport] = useState([]);
  const { Title, Text } = Typography;
  //   console.log(ordersReport);
  // state загрузчик
  const [isLoding, seIsLoding] = useState(true);

  const GetHisobot = () => {
    api
      .post("get-act-sverki", { shop_id: modalGotov.id })
      .then(function (res) {
        setOrdersReport(res.data);
        seIsLoding(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    GetHisobot();
    // eslint-disable-next-line
  }, [modalGotov]);
  const componentRef = useRef();
  if (isLoding === true) {
    return <Loding />;
  }
  if (ordersReport.length === 0) {
    return <LodingText />;
  }

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button type="primary" danger>
            Chop etish
          </Button>
        )}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>
        <div>
          <Title level={4}>
            <Text type="success">
              Buyurtma qilingan maxsulot Jami summasi:{" "}
            </Text>{" "}
            {numberFormat(ordersReport[0]?.jami_buyurtma_summa)}
          </Title>
          <Title level={4}>
            <Text type="success">Buyurtma jarayonida to'langan summa: </Text>{" "}
            {numberFormat(ordersReport[0]?.jami_buyurtma_tulov)}
          </Title>

          <Title level={4}>
            <Text type="success">Aloxida to'lovlar: </Text>
            {numberFormat(ordersReport[0]?.jami_tulov)}
          </Title>
          <Title level={4}>
            <Text type="success">Qo'llangan chegirma: </Text>
            {numberFormat(
              ordersReport[0]?.jami_buyurtma_summa -
                ordersReport[0]?.jami_buyurtma_summa2
            )}
          </Title>

          <Title level={4}>
            <Text type="danger">Farq(Joriy qarzdorlik): </Text>
            {numberFormat(
              ordersReport[0]?.jami_qarz - ordersReport[0]?.jami_tulov
            )}
          </Title>
        </div>
        <Divider>Buyurtmalar bo'yicha hisobot</Divider>
        <OrdersReportHisOrders orders={ordersReport[0]?.orders} />
        <Divider>To'lovlar</Divider>
        <OrdersReportHisTulov tulov={ordersReport[0]?.tulov} />
      </div>
    </>
  );
};
