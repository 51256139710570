import { DeleteOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import React from "react";

export default function StockList(props) {
  const { loading, dataList, onDelete, footerComponent, deletes } = props;
  return (
    <List
      loading={loading}
      footer={footerComponent}
      bordered
      dataSource={dataList}
      renderItem={(item, index) => (
        <List.Item>
          <List.Item.Meta title={index + 1 + "  " + item.name} />
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0 }}>soni: {item.count}</p>
            <div>
              {deletes && (
                <Button
                  loading={loading}
                  type="primary"
                  danger
                  onClick={() => onDelete(item.id)}
                >
                  <DeleteOutlined />
                </Button>
              )}
            </div>
          </div>
        </List.Item>
      )}
    />
  );
}
