import { Table, Typography } from "antd";
import React from "react";
import LodingText from "../Loading/LodingText";
import { nanoid } from "nanoid";
const { Title } = Typography;
const columns = [
  {
    title: "№",
    dataIndex: "pr_id",
    width: 20,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  {
    title: "Nomi",
    dataIndex: "nomi",
  },
  {
    title: "Soni",
    dataIndex: "soni",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.soni - b.soni,
  },
];

export default function OrdersRetunList({
  interval,
  componentRef,
  eltuvchi,
  dateOne,
  date2,
}) {
  const DataAgent =
    eltuvchi.length !== 0 ? eltuvchi.map((i) => i.fio) : "Barchasi";
  return (
    <div ref={componentRef}>
      <div>
        {" "}
        <Title level={2}>Eltuvchi: {DataAgent} </Title>
        <span style={{ color: "#ff4d4f", fontSize: 18 }}>
          {dateOne} - {date2}
        </span>
      </div>
      {interval.length === 0 ? (
        <LodingText />
      ) : (
        <Table
          rowKey={() => nanoid()}
          columns={columns}
          dataSource={interval}
          pagination={false}
          style={{ fontSize: 20 }}
        />
      )}
    </div>
  );
}
