import { Shops } from "Context/context";
import api from "http";
import React, { useState } from "react";

export default function ShopState({ children }) {
  const [shop, setShop] = useState([]);
  const getShop = () => {
    api
      .post("shops-get")
      .then(function (res) {
        if (res.status === 200) {
          setShop(res.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return <Shops.Provider value={{ getShop, shop }}>{children}</Shops.Provider>;
}
