import React from "react";
import { Table, Typography } from "antd";
import LodingText from "../Loading/LodingText";
// import { nanoid } from "nanoid";
import { numberFormat } from "util/NumberFormat";
import { timestempConvert } from "util/timestampConvert";
import css from "../Order/StyleOrders.module.css";

const { Title } = Typography;

//   dukon_name: "mirkomil";
//   eltuvchi_name: "Азиз доставка";
//   id: "1";
//   order_id: "50";
//   sana: "14-02-2023";
//   summa: "540000";
//   user_id: "30";
const columns = [
  {
    title: "№",
    dataIndex: "id",
    width: 20,
    render: (_, record, index) => {
      return index + 1;
    },
  },
  {
    width: 80,
    title: "Buyurtna raqami",
    dataIndex: "order_id",
  },
  {
    title: "Sana",
    dataIndex: "sana",
    render: (_, rec) => {
      return timestempConvert(rec.sana);
    },
  },

  {
    title: "Do'kon nomi",
    dataIndex: "dukon_name",
  },

  {
    title: "Summa",
    dataIndex: "summa",
    render: (_, record) => {
      return numberFormat(record.summa);
    },
  },
  {
    title: "Masul",
    dataIndex: "eltuvchi_name",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.eltuvchi_name - b.eltuvchi_name,
  },
];

const onChange = (pagination, filters, sorter, extra) => {
  //   console.log("params", pagination, filters, sorter, extra);
};
export const OrdersReturnTable = ({
  interval,
  componentRef,
  eltuvchi,
  dateOne,
  date2,
}) => {
  const DataAgent =
    eltuvchi.length !== 0 ? eltuvchi.map((i) => i.fio) : "Barchasi";
  return (
    <>
      <div ref={componentRef}>
        <div>
          <Title level={2}>Eltuvchi: {DataAgent} </Title>
          <span style={{ color: "#ff4d4f", fontSize: 18 }}>
            {dateOne} - {date2}
          </span>
        </div>
        {interval.length === 0 ? (
          <LodingText />
        ) : (
          <Table
            rowKey={(row) => row.id}
            columns={columns}
            dataSource={interval}
            onChange={onChange}
            pagination={false}
            style={{ fontSize: 20 }}
            expandable={{
              expandedRowRender: (record) => (
                <table className={css.OrdersDetailedTable}>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Nomi</th>
                      <th>Soni</th>
                      <th>Narxi</th>
                      <th>Summa</th>
                    </tr>
                  </thead>
                  <tbody>
                    {record.products.map((i, index) => (
                      <tr key={i.id} className={css.OrdersDetailedTr}>
                        <td className={css.OrdersDetailedTd}>{index + 1}</td>
                        <td className={css.OrdersDetailedTd}>{i.nomi}</td>
                        <td className={css.OrdersDetailedTd}>{i.count}</td>
                        <td className={css.OrdersDetailedTd}>
                          {numberFormat(i.price)}
                        </td>
                        <td className={css.OrdersDetailedTd}>
                          {numberFormat(i.summa)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ),
              //  defaultExpandedRowKeys: ["3"],
            }}
          />
        )}
      </div>
    </>
  );
};
