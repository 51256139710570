import React, { useState } from "react";
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
  message,
} from "antd";
import ModalCenter from "../ModalComponent/ModalComponent/ModalCenter/ModalCenter";
import swal from "sweetalert";
import { NumericFormat } from "react-number-format";
import ImgCrop from "antd-img-crop";
import { CloudUploadOutlined } from "@ant-design/icons";
import api from "http";
import { configs } from "config";
import { useProduct } from "stor/product/useProduct";

const { TextArea } = Input;

export const ProductAdd = ({ category }) => {
  const { addProduct, RefPrice, form } = useProduct();

  // действия с upload
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const uploadButton = (
    <div style={{ width: "100%" }}>
      <CloudUploadOutlined />
      <p className="ant-upload-text">Format JPG, PNG, JPEG</p>
      <p className="ant-upload-hint">Faylni tanlash</p>
    </div>
  );

  const dataCategory = category
    ? category.map((i) => (
        <Select.Option key={i.id} value={i.id}>
          {i.name}
        </Select.Option>
      ))
    : null;

  return (
    <ModalCenter name="Maxsulot qo'shish">
      <h1>Yangi maxsulot qo'shish</h1>
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={addProduct}
        autoComplete="off"
      >
        <Form.Item
          label="Naxsulot nomi"
          name="name"
          rules={[
            {
              required: true,
              message: "Maxsulot nomini kiriting!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Hajmini kiriting"
          name="soni"
          rules={[
            {
              required: true,
              type: "number",
              message: "Maxsulot hajmini kiriting!",
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="O'lchov birligini tanlang" name={"birlik"}>
          <Select>
            <Select.Option value="gramm">Gramm</Select.Option>
            <Select.Option value="dona">Dona</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Toifani tanlang" name={"category_id"}>
          <Select>{dataCategory}</Select>
        </Form.Item>

        <Form.Item label="Narxi">
          <NumericFormat
            allowLeadingZeros
            thousandSeparator={true}
            customInput={Input}
            getInputRef={RefPrice}
          />
        </Form.Item>
        <Form.Item label="Izoh" name="izoh">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          name="img"
          label="Rasm"
          valuePropName="picture"
          // rules={[
          //   {
          //     required: true,
          //     message: "Rasm tanlang!",
          //   },
          // ]}
        >
          <ImgCrop
            showGrid
            rotationSlider
            aspectSlider
            modalCancel="Закрыть"
            modalOk={"Добавить"}
            modalTitle="Настройки изоброжения"
            onModalOk={(file) => {
              const formData = new FormData();
              formData.append("images", file);
              api
                .post(configs.uploadImg, formData)
                .then((json) => {
                  if (json.data.status === "success") {
                    message.success("Rasm yuklandi");
                    form.setFieldsValue({
                      img: json.data.data.image,
                      duration: 3,
                    });
                  } else
                    swal({
                      title: json.status,
                      text: json.xabar,
                      icon: "error",
                      button: "Ок",
                    });
                })
                .catch((err) => {
                  swal({
                    title: "ERROR",
                    text: `${err}`,
                    icon: "error",
                    button: "Ок",
                  });
                });
            }}
          >
            <Upload
              style={{ width: "100vw", zIndex: 99999 }}
              action=""
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              progress
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Qabul qilish
          </Button>
        </Form.Item>
      </Form>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <Image src={previewImage} alt="image" width={330} height={350} />
      </Modal>
    </ModalCenter>
  );
};
